import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from './PrivateRoute';
import { Avaluo, AvaluoAprobacion, AvaluoAutorizacion, AvaluoAutorizado, AvaluoPendienteAutorizacion, Colaborador, ConsultaAvaluo, EstadoAvaluo, GeneracionAvaluo, Menu, SolicitudAvaluo, Usuario } from "../modules";
import { Footer, Navbar } from "../ui";

//rols 1-usuario normal 2-admin 3-superadmin
//workPosition 1-jefe agencia 2-oficial 3-admin 4-consultas 5-cobros 6-coordinador cobros

export const PrivateRouteContainer = () => {
    return (
        <div className="AltoBody animate__animated animate__fadeIn">
            <Navbar />
            <Routes>

                <Route element={<PrivateRoute rols={[1]} />}>
                    <Route path='/usuario' element={<Usuario />} />
                    <Route path='/colaborador' element={<Colaborador />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 3]} />}>
                    <Route path='/avaluo' element={<Avaluo />} />
                    <Route path='/estadoavaluo' element={<EstadoAvaluo />} />
                    <Route path='/avaluo/generacion/:id' element={<GeneracionAvaluo />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 4]} />}>
                    <Route path='/pendienteaprobacion' element={<AvaluoAprobacion />} />
                    <Route path='/pendienteautorizados' element={<AvaluoAutorizacion />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 2]} />}>
                    <Route path='/pendienteautorizacion' element={<AvaluoPendienteAutorizacion />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 2, 4]} />}>
                    <Route path='/autorizados' element={<AvaluoAutorizado />} />
                </Route>

                <Route element={<PrivateRoute rols={[6]} />}>
                    <Route path='/solicitud' element={<SolicitudAvaluo />} />
                </Route>

                <Route element={<PrivateRoute rols={[5, 6]} />}>
                    <Route path='/consultas' element={<ConsultaAvaluo />} />
                </Route>

                <Route element={<PrivateRoute rols={[1, 2, 3, 4, 5, 6]} />}>
                    <Route path='/' element={<Menu />} />
                    <Route path="/*" element={<Navigate to='/' />} />
                </Route>

            </Routes>
            <Footer />
        </div>
    )
}
