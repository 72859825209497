import { useForm } from "react-hook-form";
import { decryptId, questionModal } from "../../../../utils";
import { InputNumber, NormalButton2, SelectOption, TextArea } from "../../../../ui";
import { useObservacion } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

let colores = [{ color: 'Amarillo' }, { color: 'Rojo' }, { color: 'Verde' }, { color: 'Azul' }, { color: 'Celeste' }, { color: 'Sin color' }]

export const CrearObservacion = ({ setShow }) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const { observaciones, crearObservacionIndividual } = useObservacion();
    const { id } = useParams();

    useEffect(() => {
        let numero = observaciones.length + 1;
        setValue('orden', numero);
        // eslint-disable-next-line
    }, [])


    return (
        <div className='flex justify-center mt-4'>
            <div className='w-full max-w-7xl bg-gray-50 rounded-lg shadow-2xl p-6'>
                <div className=" mx-auto p-4">
                    <form onSubmit={handleSubmit((data) => questionModal('Crear', '¿Desea guardar el registro?', () => crearObservacionIndividual(decryptId(id), data, setShow)))}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="my-2 w-full px-3 ">
                                <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">CREAR INFRAESTRUCTURA</h1>
                            </div>
                            <TextArea nameLabel='Observación' register={register} name="observacion" message="El dato es requerido" errors={errors} Placeholder="Ej. escribe tu comentario" />
                            <SelectOption nameLabel='Color' register={register} name='color' message='El dato es requerido' errors={errors} array={colores} valueOption='color' nameOption='color' position="md:w-1/2" />
                            <InputNumber nameLabel='Orden' register={register} name="orden" message="El dato es requerido" position="md:w-1/2" errors={errors} Placeholder="Ej. 1" disabled={true} />

                            <div className="w-full mx-3 mt-1 text-center flex justify-end">
                                <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title='Guardar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}
