import { useEffect, useState } from 'react'
import { useAvaluoSlice } from '../../../hooks'
import { useForm } from 'react-hook-form';
import { TrTable, TdTable, ThTable } from '../components';
import { NavLink } from 'react-router-dom';
import { InputSearch, Loader, Pagination, SelectOption2, TransitionPopover } from '../../../ui';
import { encryptId, questionModal } from '../../../utils';
import { EditarAvaluo } from './EditarAvaluo';
import { InformeAvaluo } from './InformeAvaluo';

let arregloEstados = [
    { id_estado: 1, estado: 'Pendientes de aprobación' },
    { id_estado: 2, estado: 'Pendientes de autorización' },
    { id_estado: 3, estado: 'Autorizados' }
]

export const EstadoAvaluo = () => {

    const { register, watch, setValue } = useForm();
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const { avaluos, totalPaginas, obtenerTotalAvaluos, obtenerAvaluoIndividual, avaluoInforme, loading, generarAvaluoPDF } = useAvaluoSlice();

    let watchItems = watch();

    useEffect(() => {
        if (watchItems.estado === undefined) {
            obtenerTotalAvaluos(watchItems.buscador, page, 1);
        } else {
            obtenerTotalAvaluos(watchItems.buscador, page, watchItems.estado);
        }
        // eslint-disable-next-line
    }, [watchItems.buscador, page, watchItems.estado]);

    useEffect(() => {
        setPage(1);
    }, [watchItems.buscador, watchItems.estado]);

    const avaluoIndividual = (id_avaluo) => {
        obtenerAvaluoIndividual(id_avaluo);
        setShowEdit(true);
    }

    const visualizarInforme = (id_avaluo) => {
        avaluoInforme(id_avaluo, setShow);
    }

    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-14'>
                <div className='my-5 text-gray-400 font-bold text-4xl'>
                    <h1>
                        ESTADO DE LOS AVALÚOS
                        <hr className='w-1/4' />
                    </h1>

                </div>
                <div className='flex lg:flex-nowrap lg:justify-start flex-wrap'>
                    <InputSearch register={register} name="buscador" position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                    <SelectOption2 register={register} name="estado" array={arregloEstados} valueOption='id_estado' nameOption='estado' position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                </div>
                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Propietario' />
                            <ThTable titulo='Solicitante' />
                            <ThTable titulo='Ubicación Legal' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            avaluos.map((item, index) => (
                                <TrTable key={index}>
                                    <TdTable titulo='Propietario' valor={item.propietario} />
                                    <TdTable titulo='Solicitante' valor={item.solicitante} />
                                    <TdTable titulo='Ubicación Legal' valor={item.ubicacion_legal} />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>
                                            {
                                                item.autorizacion === 3 ?
                                                    <>
                                                        <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                            <svg onClick={() => visualizarInforme(item.id_avaluo)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            </svg>
                                                        </div>
                                                        <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110 ">
                                                            <svg onClick={() => questionModal('Generar PDF', '¿Desea generar en un PDF el expediente del avalúo?', () => generarAvaluoPDF(item.id_avaluo, item.solicitante))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
                                                            </svg>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                            <NavLink to={`/avaluo/generacion/${encryptId(item.id_avaluo)}`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                                                </svg>
                                                            </NavLink>
                                                        </div>
                                                        <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                                                            <svg onClick={() => avaluoIndividual(item.id_avaluo)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                            </svg>
                                                        </div>
                                                        <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                            <svg onClick={() => visualizarInforme(item.id_avaluo)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                            </svg>
                                                        </div>
                                                    </>
                                            }

                                        </div>
                                    } />
                                </TrTable>
                            ))
                        }
                    </tbody>
                </table>
                <div className="flex justify-end mt-3">
                    <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                </div>
                <TransitionPopover show={show} setShow={setShow}>
                    <InformeAvaluo setShow={setShow} />
                </TransitionPopover>

                <TransitionPopover show={showEdit} setShow={setShowEdit}>
                    <EditarAvaluo setShowEdit={setShowEdit} setValue2={setValue} />
                </TransitionPopover>
            </div>
        </>
    )
}
