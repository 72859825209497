import { useEffect, useState } from "react";
import { Loader, NormalButton, SelectOption, TransitionPopover } from "../../../../ui"
import { TdTable, ThTable, TrTable } from "../../components";
import { decryptId, questionModal } from "../../../../utils";
import { usePlusvaliaMinusvalia } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CrearPlusvaliaMinusvalia3 } from "./CrearPlusvaliaMinusvalia3";

let plusvaliasMinusvalias = [{ tipo: 'Plusvalía' }, { tipo: 'Minusvalía' }];
export const PlusvaliaMinusvalia = () => {

    const { id } = useParams();
    const [show, setShow] = useState(false);
    const { plusvaliaMinusvalia, loading, obtenerPlusvaliasMinusvaliasCompletas, eliminarPlusvaliaMinusvaliaIndividual } = usePlusvaliaMinusvalia();
    const { register, watch, formState: { errors } } = useForm();
    let watchItems = watch();

    useEffect(() => {
        obtenerPlusvaliasMinusvaliasCompletas(watchItems.plusvaliaMinusvalia, decryptId(id));
        // eslint-disable-next-line
    }, [id, watchItems.plusvaliaMinusvalia]);

    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-10'>
                <div className='flex lg:flex-nowrap lg:justify-between flex-wrap'>

                    <SelectOption nameLabel='Tipo' register={register} name='plusvaliaMinusvalia' message='El dato es requerido' errors={errors} array={plusvaliasMinusvalias} valueOption='tipo' nameOption='tipo' position="lg:w-1/4" requerido={false} />


                    <div className="flex items-end">
                        <NormalButton funcion={() => setShow(true)} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="px-4 py-2 mb-6 rounded" title={
                            <div className="flex">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>
                                Crear registro
                            </div>
                        } />
                    </div>
                </div>
                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Tipo' />
                            <ThTable titulo='Comentario' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            plusvaliaMinusvalia.map((item, index) => (
                                <TrTable key={index}>
                                    <TdTable titulo='Tipo' valor={item.tipo} />
                                    <TdTable titulo='Comentario' valor={item.comentario} />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>
                                            <div className="w-4 transform hover:text-red-500 hover:scale-110">
                                                <svg onClick={() => questionModal('Eliminar', '¿Desea eliminar este registro?', () => eliminarPlusvaliaMinusvaliaIndividual(item.id_plusvalia_minusvalia, watchItems.plusvaliaMinusvalia, decryptId(id)))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                </svg>
                                            </div>
                                        </div>
                                    } />
                                </TrTable>
                            ))
                        }
                    </tbody>
                </table>
                <TransitionPopover show={show} setShow={setShow}>
                    <CrearPlusvaliaMinusvalia3 setShow={setShow} />
                </TransitionPopover>
            </div >
        </>
    )
}
