import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        usuarios: [],
        valuadores: [],
        usuario: {},
        roles: [],
        loading: false,
        cantidad: null,
        totalPaginas: null
    },
    reducers: {
        valuadoresCredentials: (state, { payload }) => {
            state.valuadores = payload.usuarios;
            state.loading = false;
        },
        usuariosCredentials: (state, { payload }) => {
            state.usuarios = payload.usuarios;
            state.cantidad = payload.cantidad;
            state.totalPaginas = payload.totalPaginas;
            state.loading = false;
        },
        usuarioIndividualCredentials: (state, { payload }) => {
            state.usuario = payload.usuario;
            state.loading = false;
        },
        rolesCredentials: (state, { payload }) => {
            state.roles = payload.roles;
            state.loading = false;
        },
        isLoading: (state) => {
            state.loading = true;
        },
        loaded: (state) => {
            state.loading = false;
        }
    }
});


// Action creators are generated for each case reducer function
export const { valuadoresCredentials, isLoading, loaded, usuariosCredentials, usuarioIndividualCredentials, rolesCredentials } = userSlice.actions;