import { useEffect, useState } from "react";
import { Loader, NormalButton, TransitionPopover } from "../../../../ui"
import { TdTable, ThTable, TrTable } from "../../components";
import { decryptId, questionModal } from "../../../../utils";
import { useObservacion } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { CrearObservacion } from "./CrearObservacion";
import { EditarObservacion } from "./EditarObservacion";

export const Observacion = () => {

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const { observaciones, loading, obtenerInfraestructurasCompletas, obtenerObservacionIndividual, eliminarObservacionIndividual } = useObservacion();
    const { id } = useParams();

    useEffect(() => {
        obtenerInfraestructurasCompletas(decryptId(id));
        // eslint-disable-next-line
    }, [id])


    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-10'>
                <div className='flex justify-end'>
                    <NormalButton funcion={() => setShow(true)} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' title={
                        <div className="flex">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            Crear registro
                        </div>
                    } />
                </div>
                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Observación' />
                            <ThTable titulo='Color' />
                            <ThTable titulo='Orden' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            observaciones.map((item, index) => (
                                <TrTable key={index}>
                                    <TdTable titulo='Observación' valor={item.observacion} />
                                    <TdTable titulo='Color' valor={
                                        item.color === 'Amarillo' ?
                                            <span className="bg-yellow-400 py-1 px-3 rounded-full text-xs"></span>
                                            : item.color === 'Rojo' ?
                                                <span className="bg-red-400 py-1 px-3 rounded-full text-xs"></span>
                                                : item.color === 'Verde' ?
                                                    <span className="bg-green-400 py-1 px-3 rounded-full text-xs"></span>
                                                    : item.color === 'Azul' ?
                                                        <span className="bg-blue-400 py-1 px-3 rounded-full text-xs"></span>
                                                        : item.color === 'Celeste' ?
                                                            <span className="bg-sky-400 py-1 px-3 rounded-full text-xs"></span>
                                                            : ''
                                    } />
                                    <TdTable titulo='Orden' valor={item.orden} />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>
                                            <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                                                <svg onClick={() => obtenerObservacionIndividual(item.id_observacion, setShow2)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                </svg>
                                            </div>
                                            <div className="w-4 transform hover:text-red-500 hover:scale-110">
                                                <svg onClick={() => questionModal('Eliminar', '¿Desea eliminar esta observación?', () => eliminarObservacionIndividual(item.id_observacion, decryptId(id)))} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                </svg>

                                            </div>
                                        </div>
                                    } />
                                </TrTable>
                            ))
                        }
                        <TrTable>
                            <TdTable titulo='Observación' valor='El valor bancario del terreno es asignado de acuerdo con las referenciales obtenidos y la dinámica inmobiliaria actual del sector donde se ubica, considerando como parte integral del mismo, las mejoras realizadas para lograr su máximo aprovechamiento.' />
                            <TdTable titulo='Color' valor='' />
                            <TdTable titulo='Orden' valor='' />
                            <TdTable valor='' />
                        </TrTable>
                        <TrTable>
                            <TdTable titulo='Observación' valor='El enfoque de la valoración que se presenta es de carácter bancario, que en consideración de que el inmueble valuado será objeto de garantía hipotecaria para respaldar una obligación crediticia, al valor unitario ajustado por factores propios y del entorno.' />
                            <TdTable titulo='Color' valor='' />
                            <TdTable titulo='Orden' valor='' />
                            <TdTable valor='' />
                        </TrTable>
                    </tbody>
                </table>
                <TransitionPopover show={show} setShow={setShow}>
                    <CrearObservacion setShow={setShow} />
                </TransitionPopover>
                <TransitionPopover show={show2} setShow={setShow2}>
                    <EditarObservacion setShow2={setShow2} />
                </TransitionPopover>
            </div >
        </>
    )
}
