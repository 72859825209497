import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { TdTable, ThTable, TrTable } from "../../components";
import { InputSearch, Loader, Pagination, TransitionPopover } from "../../../../ui";
import { useAvaluoSlice } from "../../../../hooks";

export const SolicitudAvaluo = () => {

    const { register, watch } = useForm();
    const [page, setPage] = useState(1);
    const [show, setShow] = useState(false);
    const { avaluos, totalPaginas, obtenerTotalAvaluos, loading } = useAvaluoSlice();
    let watchItems = watch();


    useEffect(() => {
        obtenerTotalAvaluos(watchItems.buscador, page, 3, watchItems.valuador);
        // eslint-disable-next-line
    }, [watchItems.buscador, page, watchItems.valuador]);

    useEffect(() => {
        setPage(1);
    }, [watchItems.buscador, watchItems.valuador]);

    return (
        <>
            {loading && <Loader />}
            <div className='mx-20 my-14'>
                <div className='my-5 text-gray-400 font-bold text-4xl'>
                    <h1>
                        SOLICITUD DE AVALÚO
                        <hr className='w-1/3' />
                    </h1>

                </div>
                <div className='flex lg:flex-nowrap lg:justify-start flex-wrap'>
                    <InputSearch register={register} name="buscador" position='lg:w-1/4 mb-3 font-semibold text-gray-500 mx-1' />
                </div>
                <table className={"border-collapse w-full"}>
                    <thead>
                        <tr>
                            <ThTable titulo='Propietario' />
                            <ThTable titulo='Solicitante' />
                            <ThTable titulo='Ubicación Legal' />
                            <ThTable titulo='Acciones' />
                        </tr>
                    </thead>
                    <tbody>
                        {
                            avaluos.map((item, index) => (
                                <TrTable key={index}>
                                    <TdTable titulo='Propietario' valor={item.propietario} />
                                    <TdTable titulo='Solicitante' valor={item.solicitante} />
                                    <TdTable titulo='Ubicación Legal' valor={item.ubicacion_legal} />
                                    <TdTable valor={
                                        <div className='flex justify-center mx-2'>
                                            <div className="w-4 mr-3 transform hover:text-green-500 hover:scale-110 ">
                                                <svg     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6 cursor-pointer">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                        </div>


                                    } />
                                </TrTable>
                            ))
                        }
                    </tbody>
                </table>
                <div className="flex justify-end mt-3">
                    <Pagination totalPages={totalPaginas} actualPage={page} onChange={(newPage) => setPage(newPage)} />
                </div>
                <TransitionPopover show={show} setShow={setShow}>
                    {console.log('hola')}
                </TransitionPopover>
            </div>
        </>
    )
}
