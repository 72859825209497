import { useForm } from "react-hook-form";
import { InputDecimal, NormalButton2 } from "../../../../ui";
import { useDetalleInmuebleSlice, useValorInmueble } from "../../../../hooks";
import { useEffect } from "react";
import { decryptId, questionModal } from "../../../../utils";
import { useParams } from "react-router-dom";

export const CrearMemoriaCalculo = ({ setShow4 }) => {

  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
  const { detalle_inmueble_total } = useDetalleInmuebleSlice();
  const { descripcionReferencial, crearRegistroMemoriaCalculo } = useValorInmueble();
  let watchItems = watch();
  const { id } = useParams();

  useEffect(() => {
    let valorBase = 0;
    let contador = 0;
    if (Number.parseFloat(detalle_inmueble_total[0].area_campo) >= 6987.37) {
      let area = (Number.parseFloat(detalle_inmueble_total[0].area_campo) / 6987.37).toFixed(2);
      let areaValuar = 0;

      if (watchItems.area !== '') {
        areaValuar = Number.parseFloat(watchItems.area);

        if (area >= areaValuar) {
          for (let i = 0; i < descripcionReferencial.length; i++) {
            if (descripcionReferencial[i].dimensional === 'Mz') {
              valorBase = valorBase + Number.parseFloat(descripcionReferencial[i].valor);
              contador = contador + 1
            }
          }
        } else {
          for (let i = 0; i < descripcionReferencial.length; i++) {
            if (descripcionReferencial[i].dimensional === 'm2') {
              valorBase = valorBase + Number.parseFloat(descripcionReferencial[i].valor);
              contador = contador + 1
            }
          }
        }
      }

    } else {
      //setValue('area', (Number.parseFloat(detalle_inmueble_total[0].area_campo)).toFixed(2));
      for (let i = 0; i < descripcionReferencial.length; i++) {
        if (descripcionReferencial[i].dimensional === 'm2') {
          valorBase = valorBase + Number.parseFloat(descripcionReferencial[i].valor);
          contador = contador + 1
        }
      }
    }

    if (contador === 0) {
      setValue('valor_base', 0);
    } else {
      setValue('valor_base', (valorBase / contador).toFixed(2))
    }
    // eslint-disable-next-line
  }, [descripcionReferencial, detalle_inmueble_total, watchItems.area])

  useEffect(() => {
    let valor1 = Number.parseFloat(watchItems.valor_base) * Number.parseFloat(watchItems.lote_interior) * Number.parseFloat(watchItems.relacion_frente_fondo) * Number.parseFloat(watchItems.forma) * Number.parseFloat(watchItems.pendiente) * Number.parseFloat(watchItems.esquina) * Number.parseFloat(watchItems.bajo_sobre_nivel) * Number.parseFloat(watchItems.uso_inmueble);
    let valor2 = Number.parseFloat(watchItems.luz) * Number.parseFloat(watchItems.agua) * Number.parseFloat(watchItems.drenaje) * Number.parseFloat(watchItems.condicion_acceso) * Number.parseFloat(watchItems.contaminacion) * Number.parseFloat(watchItems.mejoras) * Number.parseFloat(watchItems.deseabilidad) * Number.parseFloat(watchItems.otro);
    let valor3 = Number.parseFloat(watchItems.traslado_dominio) * Number.parseFloat(watchItems.liquidez_registral) * Number.parseFloat(watchItems.area_colindantes_medidas) * Number.parseFloat(watchItems.derecho_posesion) * Number.parseFloat(watchItems.traslape_finca) * Number.parseFloat(watchItems.acceso_servidumbre) * Number.parseFloat(watchItems.predio_inconexo) * Number.parseFloat(watchItems.predio_enclavado) * Number.parseFloat(watchItems.traslape_construccion) * Number.parseFloat(watchItems.uso_posesion) * Number.parseFloat(watchItems.construccion_uso_especifico) * Number.parseFloat(watchItems.ubicacion) * Number.parseFloat(watchItems.plano) * Number.parseFloat(watchItems.otros);
    let valorBancario = (valor1 * valor2 * valor3 * 0.7).toFixed(2);

    let cociente = Math.floor(valorBancario / 1);
    let valorAjustadoBancario = (cociente * 1).toFixed(2);
    let valorAjustado = (valorAjustadoBancario * Number.parseFloat(watchItems.area)).toFixed(2);

    setValue('valor_bancario', valorBancario);
    setValue('valor_ajustado_bancario', valorAjustadoBancario);
    setValue('valor_ajustado', valorAjustado);
    // eslint-disable-next-line
  }, [watchItems.acceso_servidumbre, watchItems.agua, watchItems.area, watchItems.area_colindantes_medidas, watchItems.bajo_sobre_nivel, watchItems.condicion_acceso, watchItems.construccion_uso_especifico, watchItems.contaminacion, watchItems.derecho_posesion, watchItems.deseabilidad, watchItems.drenaje, watchItems.esquina, watchItems.forma, watchItems.liquidez_registral, watchItems.lote_interior, watchItems.luz, watchItems.mejoras, watchItems.otro, watchItems.otros, watchItems.pendiente, watchItems.plano, watchItems.predio_enclavado, watchItems.predio_inconexo, watchItems.relacion_frente_fondo, watchItems.traslado_dominio, watchItems.traslape_construccion, watchItems.traslape_finca, watchItems.ubicacion, watchItems.uso_inmueble, watchItems.uso_posesion, watchItems.valor_base])

  const separator = (numb) => {
    if (numb) {
      var str = numb.toString().split(".");
      str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return str.join(".");
    } else {
      return 0.00
    }
  }

  return (
    <div className='flex justify-center mt-4'>
      <div className='w-full max-w-screen-2xl bg-gray-50 rounded-lg shadow-2xl p-6'>
        <form onSubmit={handleSubmit((data) => questionModal('Crear', '¿Desea crear el registro?', () => crearRegistroMemoriaCalculo(decryptId(id), data, setShow4)))}>
          <div className="flex flex-wrap -mx-3 mb-6">

            <div className="mb-5 w-full px-3 flex justify-end">
              <h1 className="text-xl font-bold mt-2 text-gray-500">{`${separator(detalle_inmueble_total[0].area_campo)} m2 = ${(Number.parseFloat(detalle_inmueble_total[0].area_campo) / 6987.37).toFixed(2)} Mz`} </h1>
            </div>

            <div className="mb-2 w-full px-3 ">
              <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-300 text-gray-500">MEMORIA</h1>
            </div>
            <InputDecimal nameLabel='Área' register={register} name="area" message="El dato es requerido" position="md:w-1/2" errors={errors} Placeholder="Ej. 17.35" />
            <InputDecimal nameLabel='Valor base' register={register} name="valor_base" message="El dato es requerido" position="md:w-1/2" errors={errors} Placeholder="Ej. 50000" disabled={true} />

            <InputDecimal nameLabel='Lote interior' register={register} name="lote_interior" message="El dato es requerido" position="md:w-1/5" errors={errors} Placeholder="Ej. 0.95" />
            <InputDecimal nameLabel='Relación frente/fondo' register={register} name="relacion_frente_fondo" message="El dato es requerido" position="md:w-1/5" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Forma' register={register} name="forma" message="El dato es requerido" position="md:w-1/5" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Pendiente' register={register} name="pendiente" message="El dato es requerido" position="md:w-1/5" errors={errors} Placeholder="Ej. 0.9" />
            <InputDecimal nameLabel='Esquina' register={register} name="esquina" message="El dato es requerido" position="md:w-1/5" errors={errors} Placeholder="Ej. 0.95" />
            <InputDecimal nameLabel='Bajo/sobre nivel' register={register} name="bajo_sobre_nivel" message="El dato es requerido" position="md:w-1/5" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Uso del inmueble' register={register} name="uso_inmueble" message="El dato es requerido" position="md:w-1/5" errors={errors} Placeholder="Ej. 0.8" />

            <div className="mb-2 w-full px-3 ">
              <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-300 text-gray-500">FACTORES INTANGIBLES</h1>
            </div>
            <InputDecimal nameLabel='Luz' register={register} name="luz" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.95" />
            <InputDecimal nameLabel='Agua' register={register} name="agua" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Drenaje' register={register} name="drenaje" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Condiciones de acceso' register={register} name="condicion_acceso" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.9" />
            <InputDecimal nameLabel='Contaminación' register={register} name="contaminacion" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.95" />
            <InputDecimal nameLabel='Mejoras' register={register} name="mejoras" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Deseabilidad' register={register} name="deseabilidad" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='otro' register={register} name="otro" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />

            <div className="mb-2 w-full px-3 ">
              <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-300 text-gray-500">FACTOR RIESGO</h1>
            </div>
            <InputDecimal nameLabel='Traslado del dominio' register={register} name="traslado_dominio" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.95" />
            <InputDecimal nameLabel='Liquidez registral' register={register} name="liquidez_registral" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Área, colindantes y medidas' register={register} name="area_colindantes_medidas" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Derecho de posesión' register={register} name="derecho_posesion" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.9" />
            <InputDecimal nameLabel='Traslape de finca' register={register} name="traslape_finca" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.95" />
            <InputDecimal nameLabel='Acceso, servidumbres' register={register} name="acceso_servidumbre" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Predio inconexo' register={register} name="predio_inconexo" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Predio enclavado' register={register} name="predio_enclavado" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Traslape de construcción' register={register} name="traslape_construccion" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.95" />
            <InputDecimal nameLabel='Uso y posesión' register={register} name="uso_posesion" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Construcciones de uso específico' register={register} name="construccion_uso_especifico" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />
            <InputDecimal nameLabel='Ubicación' register={register} name="ubicacion" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.9" />
            <InputDecimal nameLabel='Plano' register={register} name="plano" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.95" />
            <InputDecimal nameLabel='Otros' register={register} name="otros" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.8" />

            <div className="mb-2 w-full px-3 ">
              <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-300 text-gray-500">RESULTADOS</h1>
            </div>
            <InputDecimal nameLabel='Valor bancario' register={register} name="valor_bancario" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 25521" disabled={true} />
            <InputDecimal nameLabel='Valor ajustado bancario' register={register} name="valor_ajustado_bancario" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 25000" disabled={true} />
            <InputDecimal nameLabel='Valor ajustado' register={register} name="valor_ajustado" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 443445" disabled={true} />

            <div className="w-full mx-3 mt-1 text-center flex justify-end">
              <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-8  py-2 rounded-md cursor-pointer" title='Guardar' />
            </div>


          </div>
        </form>
      </div>
    </div>

  )
}
