import { configureStore } from "@reduxjs/toolkit"
import { authSlice } from "./slice/auth";
import { avaluoSlice } from "./slice/avaluo";
import { registroPropiedadSlice } from "./slice/registroPropiedad";
import { detalleInmuebleSlice } from "./slice/detalleInmueble";
import { infraestructuraSlice } from "./slice/infraestructura";
import { plusvaliaMinusvaliaSlice } from "./slice/plusvaliaMinusvalia";
import { observacionSlice } from "./slice/observacion";
import { valorInmuebleSlice } from "./slice/valorInmueble";
import { anexoSlice } from "./slice/anexo";
import { userSlice } from "./slice/user";
import { colaboradorSlice } from "./slice/colaborador";
import { agenciaSlice } from "./slice/agencia";
import { puestoSlice } from "./slice/puesto/puestoSlice";

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        avaluos: avaluoSlice.reducer,
        registroPropiedad: registroPropiedadSlice.reducer,
        detalleInmueble: detalleInmuebleSlice.reducer,
        infraestructura: infraestructuraSlice.reducer,
        plusvaliaMinusvalia: plusvaliaMinusvaliaSlice.reducer,
        observacion: observacionSlice.reducer,
        valorInmueble: valorInmuebleSlice.reducer,
        anexo: anexoSlice.reducer,
        user: userSlice.reducer,
        colaborador: colaboradorSlice.reducer,
        agencia: agenciaSlice.reducer,
        puesto: puestoSlice.reducer
    }
});
