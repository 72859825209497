import { apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { isLoading, loaded, rolesCredentials, usuarioIndividualCredentials, usuariosCredentials, valuadoresCredentials } from "./userSlice";

export const obtenerValuadores = () => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/usuario/valuador', { headers: { 'token': getToken() } });
            dispatch(valuadoresCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al cargar la información de los valuadores');
        }
    }
}

export const obtenerUsuarios = (busqueda = '', pagina = '') => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/usuario', { headers: { 'token': getToken() }, params: { busqueda, pagina } });
            dispatch(usuariosCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al cargar la información de los usuarios');
        }
    }
}

export const obtenerUsuarioIndividual = (id, setShow2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/usuario/individual', { headers: { 'token': getToken() }, params: { id } });
            dispatch(usuarioIndividualCredentials(data));
            setShow2(true);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al cargar la información del usuario');
        }
    }
}

export const obtenerRoles = () => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/rol', { headers: { 'token': getToken() } });
            dispatch(rolesCredentials(data));
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al cargar la información de los roles');
        }
    }
}

export const crearUsuario = (data, setShow, setValue) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/usuario/crear', data, { headers: { 'token': getToken() } });
            dispatch(loaded())
            successModal('Registro creado correctamente', 'center');
            setShow(false);
            setValue('buscador', data.nombre);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al crear el usuario');
        }
    }
}

export const editarUsuario = (id, data, setShow2, setValue2) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/usuario/editar', data, { headers: { 'token': getToken() }, params: { id } });
            dispatch(loaded());
            successModal('Registro editado correctamente', 'center');
            setShow2(false);
            setValue2('buscador', data.nombre);
        } catch (error) {
            interceptorResponse(error);
            dispatch(loaded());
            errorModal('Ocurrió un error al crear el usuario');
        }
    }
}
