import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useAvaluoSlice } from "../../../../../../hooks";
import { InputDate, InputText, NormalButton2, SelectOption } from "../../../../../../ui";
import { departamentos } from "../../../../../../utils";

const adquisicion1 = [{ adquisicion: "desmembracion" }, { adquisicion: "adjudicación" }, { adquisicion: "partición" }, { adquisicion: "titulación supletoria" }]
const adquisicion2 = [{ adquisicion: "compraventa" }, { adquisicion: "donación" }, { adquisicion: "partición" }, { adquisicion: "adjudicación" }]

export const RegistroFase1 = ({ tab, setTab, formulario, setFormulario }) => {

  const { avaluoIndividual } = useAvaluoSlice();
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  useEffect(() => {
    setValue('ub_registral', avaluoIndividual.ubicacion_legal);
    setValue('propiedad_de', avaluoIndividual.propietario);
    // eslint-disable-next-line
  }, [avaluoIndividual])

  useEffect(() => {
    if (formulario.finca) {
      setValue('finca', formulario.finca);
      setValue('folio', formulario.folio);
      setValue('libro', formulario.libro);
      setValue('departamento', formulario.departamento);
      setValue('forma_adquisicion_1', formulario.forma_adquisicion_1);
      setValue('finca_matriz', formulario.finca_matriz);
      setValue('forma_adquisicion_2', formulario.forma_adquisicion_2);
      setValue('fecha_inscripcion', formulario.fecha_inscripcion);
      setValue('numero_inscripcion', formulario.numero_inscripcion);
      setValue('fuente', formulario.fuente);
      setValue('fecha_fuente', formulario.fecha_fuente);
      setValue('plano', formulario.plano);
    }
    // eslint-disable-next-line
  }, [])


  const almacenarDatosFormulario = (data) => {
    setFormulario({ ...formulario, ...data });
    setTab(tab + 1)
  }

  return (
    <div className=" mx-auto p-4">
      <form onSubmit={handleSubmit((data) => almacenarDatosFormulario(data))}>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="my-2 w-full px-3 ">
            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Identificación registral</h1>
          </div>
          <InputText nameLabel='Finca' register={register} name="finca" message="La finca es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 5997" />
          <InputText nameLabel='Folio' register={register} name="folio" message="El folio es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 510" />
          <InputText nameLabel='Libro' register={register} name="libro" message="El libro es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 110E" />
          <SelectOption nameLabel='Departamento' register={register} name='departamento' message='El departamento es requerido' errors={errors} array={departamentos} valueOption='departamento' nameOption='departamento' position="md:w-1/3" />
          {/* <SelectOptionDepartamentos nameLabel='Departamento' register={register} name='departamento' message='El departamento es requerido' errors={errors} array={GT.departamentos()} position="md:w-1/3" /> */}

          <div className="my-2 w-full px-3 ">
            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Ubicación registral</h1>
          </div>
          <InputText nameLabel='Ubicación registral' register={register} name="ub_registral" message="El departamento es requerido" errors={errors} Placeholder="Ej. Alta Verapaz." disabled={true} />

          <div className="my-2 w-full px-3 ">
            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Origen registral</h1>
          </div>
          <SelectOption nameLabel='Forma de adquisición' register={register} name='forma_adquisicion_1' message='La forma de adquisición es requerida' errors={errors} array={adquisicion1} valueOption='adquisicion' nameOption='adquisicion' position="md:w-1/2" />
          <InputText nameLabel='Finca Matriz' register={register} name="finca_matriz" message="La finca matriz es requerida" position="md:w-1/2" errors={errors} Placeholder="Ej. Finca 144 Folio 172 Libro 19 de Primera Serie." />

          <div className="my-2 w-full px-3 ">
            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Titular registral</h1>
          </div>
          <InputText nameLabel='Propiedad de' register={register} name="propiedad_de" message="El departamento es requerido" errors={errors} Placeholder="Ej. Alta Verapaz." disabled={true} />
          <SelectOption nameLabel='Forma de adquisición' register={register} name='forma_adquisicion_2' message='La forma de adquisición es requerida' position="md:w-1/3" errors={errors} array={adquisicion2} valueOption='adquisicion' nameOption='adquisicion' />
          <InputDate nameLabel='Fecha de inscripción' register={register} name='fecha_inscripcion' message='La fecha es requerida' errors={errors} position="md:w-1/3" />
          <InputText nameLabel='Número de inscripción' register={register} name="numero_inscripcion" message="El número es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 10" />

          <div className="my-2 w-full px-3 ">
            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Fuente</h1>
          </div>
          <InputText nameLabel='Fuente' register={register} name="fuente" message="La fuente es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. Consulta electrónica" />
          <InputDate nameLabel='Fecha de la fuente' register={register} name='fecha_fuente' message='La fecha del acuerdo es requerida' errors={errors} position="md:w-1/3" />
          <InputText nameLabel='Plano' register={register} name="plano" message="El plano es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. Ing. Juan Armando" />


          <div className="w-full mx-3 mt-5 text-center flex justify-end">
            <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={
              <div className="flex">
                Siguiente
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </div>
            } />
          </div>
        </div>
      </form>
    </div>
  )
}
