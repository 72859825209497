import { useEffect, useState } from "react";
import { Loader, NormalButton, TransitionPopover } from "../../../../ui"
import { TdTable, ThTable, TrTable } from "../../components"
import { useDetalleInmuebleSlice, useInfraestructuraSlice, useValorInmueble } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { decryptId, questionModal } from "../../../../utils";
import { CrearMemoriaCalculo } from "./CrearMemoriaCalculo";
import { DescripcionReferencia } from "./DescripcionReferencia";
import { ValorInfraestructura } from "./ValorInfraestructura";
import { EditarMemoriaCalculo } from "./EditarMemoriaCalculo";

export const ValorInmueble = () => {

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);

    const { valorInmueble, descripcionReferencial, loading, obtenervaloresInmuebleCompleto, obtenerDescripcionReferencialIndividual, eliminarDescripcionReferencialIndividual, eliminarValorInmuebleEspecifico, obtenerValorInmuebleIndividualTotal } = useValorInmueble();
    const { obtenerInfraestructurasCompletas, infraestructuras } = useInfraestructuraSlice();
    const { obtenerDetalleInmuebleIndividualTotal, detalle_inmueble_total } = useDetalleInmuebleSlice();
    const { id } = useParams();

    useEffect(() => {
        obtenervaloresInmuebleCompleto(decryptId(id));
        obtenerInfraestructurasCompletas(decryptId(id));
        obtenerDetalleInmuebleIndividualTotal(decryptId(id));
        // eslint-disable-next-line
    }, [id])

    const valorBancario = (posicion) => {

        let factorIntangible1 = Number.parseFloat(valorInmueble[posicion].factorIntangible[0].luz) * Number.parseFloat(valorInmueble[posicion].factorIntangible[0].agua) * Number.parseFloat(valorInmueble[posicion].factorIntangible[0].drenaje) * Number.parseFloat(valorInmueble[posicion].factorIntangible[0].condicion_acceso) * Number.parseFloat(valorInmueble[posicion].factorIntangible[0].contaminacion) * Number.parseFloat(valorInmueble[posicion].factorIntangible[0].mejoras) * Number.parseFloat(valorInmueble[posicion].factorIntangible[0].deseabilidad) * Number.parseFloat(valorInmueble[posicion].factorIntangible[0].otro);
        let factorRiesgo1 = Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].traslado_dominio) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].liquidez_registral) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].area_colindantes_medidas) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].derecho_posesion) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].traslape_finca) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].acceso_servidumbre) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].predio_inconexo) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].predio_enclavado) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].traslape_construccion) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].uso_posesion) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].construccion_uso_especifico) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].ubicacion) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].plano) * Number.parseFloat(valorInmueble[posicion].factorRiesgo[0].otros);
        let castigoInmueble = Number.parseFloat(valorInmueble[posicion].valor_inmueble.lote_interior) * Number.parseFloat(valorInmueble[posicion].valor_inmueble.relacion_frente_fondo) * Number.parseFloat(valorInmueble[posicion].valor_inmueble.forma) * Number.parseFloat(valorInmueble[posicion].valor_inmueble.pendiente) * Number.parseFloat(valorInmueble[posicion].valor_inmueble.esquina) * Number.parseFloat(valorInmueble[posicion].valor_inmueble.bajo_sobre_nivel) * Number.parseFloat(valorInmueble[posicion].valor_inmueble.uso_inmueble);

        let valorBase = 0;
        let contador = 0;
        if (Number.parseFloat(detalle_inmueble_total[0].area_campo) >= 6987.37) {
            let area = (Number.parseFloat(detalle_inmueble_total[0].area_campo) / 6987.37).toFixed(2);

            if (area >= Number.parseFloat(valorInmueble[posicion].valor_inmueble.area)) {
                for (let i = 0; i < descripcionReferencial.length; i++) {
                    if (descripcionReferencial[i].dimensional === 'Mz') {
                        valorBase = valorBase + Number.parseFloat(descripcionReferencial[i].valor);
                        contador = contador + 1
                    }
                }
            } else {
                for (let i = 0; i < descripcionReferencial.length; i++) {
                    if (descripcionReferencial[i].dimensional === 'm2') {
                        valorBase = valorBase + Number.parseFloat(descripcionReferencial[i].valor);
                        contador = contador + 1
                    }
                }
            }
        } else {
            for (let i = 0; i < descripcionReferencial.length; i++) {
                if (descripcionReferencial[i].dimensional === 'm2') {
                    valorBase = valorBase + Number.parseFloat(descripcionReferencial[i].valor);
                    contador = contador + 1
                }
            }
        }

        valorBase = valorBase / contador;

        let valorBancario = factorIntangible1 * factorRiesgo1 * castigoInmueble * 0.7 * valorBase;
        return valorBancario.toFixed(2);
    }

    const valorAjustadoBancario = (posicion) => {
        let cociente = Math.floor(valorBancario(posicion) / 1);
        let valorAjustadoBancario1 = (cociente * 1);
        return valorAjustadoBancario1.toFixed(2);
    }

    const valorAjustado = (posicion) => {
        let resultado = valorAjustadoBancario(posicion) * Number.parseFloat(valorInmueble[posicion].valor_inmueble.area);
        return resultado.toFixed(2);
    }

    const sumatoriaTotalTerrenos = () => {
        let total = 0;
        for (let i = 0; i < valorInmueble.length; i++) {
            total = total + Number.parseFloat(valorAjustado(i));
        }
        return total.toFixed(2);
    }

    const valorTotalInfraestructuras = () => {
        let valor = 0;
        for (let i = 0; i < infraestructuras.length; i++) {
            let castigo = Number.parseFloat(infraestructuras[i].valor) * Number.parseFloat(infraestructuras[i].edad_castigo) * Number.parseFloat(infraestructuras[i].mantenimiento_castigo) * Number.parseFloat(infraestructuras[i].proceso_constructivo_castigo) * Number.parseFloat(infraestructuras[i].deseabilidad_castigo) * 0.7;
            let cociente = Math.floor(castigo / 1);
            let valorAjustadoBancario = (cociente * 1);
            let valorTotal = valorAjustadoBancario * Number.parseFloat(infraestructuras[i].area);
            valor = valor + valorTotal
        }
        return valor.toFixed(2);
    }

    const sumarDatos = (A, B) => {
        let numero1 = Number.parseFloat(A);
        let numero2 = Number.parseFloat(B);
        let suma = numero1 + numero2;
        return suma.toFixed(2);
    }

    const separator = (numb) => {
        if (numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        } else {
            return 0.00
        }
    }


    return (
        <>
            {loading && <Loader />}
            <DescripcionReferencia show={show} setShow={setShow} show2={show2} setShow2={setShow2} descripcionReferencial={descripcionReferencial} obtenerDescripcionReferencialIndividual={obtenerDescripcionReferencialIndividual} eliminarDescripcionReferencialIndividual={eliminarDescripcionReferencialIndividual} id={id} />
            {
                infraestructuras.length !== 0 ?
                    <ValorInfraestructura show3={show3} setShow3={setShow3} />
                    :
                    null
            }

            {
                detalle_inmueble_total.length !== 0 ?
                    <div className='mx-20 my-10'>
                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">MEMORIA DE CÁLCULO</h1>
                        </div>

                        <div className='flex justify-end'>

                            <NormalButton funcion={() => setShow4(true)} bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' title={
                                <div className="flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-1">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    Crear registro
                                </div>
                            } />
                        </div>

                        <table className={"border-collapse w-full"}>
                            <thead>
                                <tr>
                                    <ThTable titulo='Área' />
                                    <ThTable titulo='Valor bancario' />
                                    <ThTable titulo='Valor ajustado bancario' />
                                    <ThTable titulo='Valor ajustado' />
                                    <ThTable titulo='Acciones' />
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    valorInmueble.map((item, index) => (
                                        <TrTable key={index}>
                                            <TdTable titulo='Área' valor={item.valor_inmueble.area} />
                                            <TdTable titulo='Valor bancario' valor={
                                                item.factorIntangible.length > 0 && item.factorRiesgo.length > 0 ?
                                                    `Q. ${separator(valorBancario(index))}`
                                                    :
                                                    'DEBE CALCULARSE'
                                            } />
                                            <TdTable titulo='Valor ajustado bancario' valor={
                                                item.factorIntangible.length > 0 && item.factorRiesgo.length > 0 ?
                                                    `Q. ${separator(valorAjustadoBancario(index))}`
                                                    :
                                                    'DEBE CALCULARSE'
                                            } />
                                            <TdTable titulo='Valor ajustado' valor={
                                                item.factorIntangible.length > 0 && item.factorRiesgo.length > 0 ?
                                                    `Q. ${separator(valorAjustado(index))}`
                                                    :
                                                    'DEBE CALCULARSE'
                                            } />
                                            <TdTable valor={
                                                <div className='flex justify-center mx-2'>
                                                    <div className="w-4 mr-3 transform hover:text-yellow-500 hover:scale-110">
                                                        <svg onClick={() => obtenerValorInmuebleIndividualTotal(item.valor_inmueble.id_valor_inmueble_terreno, setShow5)}
                                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                                        </svg>
                                                    </div>
                                                    <div className="w-4 transform hover:text-red-500 hover:scale-110">
                                                        <svg onClick={() => questionModal('Eliminar', '¿Desea eliminar este registro?', () => eliminarValorInmuebleEspecifico(item.valor_inmueble.id_valor_inmueble_terreno, decryptId(id)))
                                                        } xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-5 h-5 cursor-pointer">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                                        </svg>

                                                    </div>
                                                </div>
                                            } />
                                        </TrTable>
                                    ))
                                }
                            </tbody>
                        </table>
                        <TransitionPopover show={show4} setShow={setShow4}>
                            <CrearMemoriaCalculo setShow4={setShow4} />
                        </TransitionPopover>
                        <TransitionPopover show={show5} setShow={setShow5}>
                            <EditarMemoriaCalculo setShow5={setShow5} />
                        </TransitionPopover>
                    </div >
                    : null
            }
            {
                detalle_inmueble_total.length !== 0 && valorInmueble.length !== 0 ?
                    <div className='mx-20 my-10'>
                        <div className="my-2 w-1/2">
                            <h1 className="text-xl font-bold mt-2 mb-2 border-b-2 border-gray-200 text-gray-400">RESUMEN DE VALORES BANCARIOS</h1>
                            <table className={"border-collapse w-full"}>
                                <thead>
                                    <tr>
                                        <th className={`py-0.5 font-semibold uppercase bg-yellow-500 text-white border border-gray-300`}>
                                            TOTAL TERRENO(S)
                                        </th>
                                        <th className={`py-0.5 font-semibold uppercase bg-yellow-500 text-white border border-gray-300`}>
                                            {`Q. ${separator(sumatoriaTotalTerrenos())}`}
                                        </th>

                                    </tr>
                                    <tr>
                                        <th className={`py-0.5 font-semibold uppercase bg-yellow-500 text-white border border-gray-300`}>
                                            TOTAL CONSTRUCCIÓN(ES)
                                        </th>
                                        <th className={`py-0.5 font-semibold uppercase bg-yellow-500 text-white border border-gray-300`}>
                                            {`Q. ${separator(valorTotalInfraestructuras())}`}
                                        </th>

                                    </tr>
                                    <tr>
                                        <th className={`py-0.5 font-semibold uppercase bg-green-700 text-white border border-gray-300`}>
                                            TOTAL
                                        </th>
                                        <th className={`py-0.5 font-semibold uppercase bg-green-700 text-white border border-gray-300`}>
                                            {`Q. ${separator(sumarDatos(sumatoriaTotalTerrenos(), valorTotalInfraestructuras()))}`}
                                        </th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}
