import { useForm } from "react-hook-form";
import { decryptId, questionModal } from "../../../../utils";
import { useInfraestructuraSlice } from "../../../../hooks";
import { InputDecimal, InputText, NormalButton2 } from "../../../../ui";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

export const EditarValorInfraestructura = ({ setShow3 }) => {

    const { infraestructura, editarInfraestructuraIndividual } = useInfraestructuraSlice();
    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    let watchItems = watch();
    const { id } = useParams();

    useEffect(() => {
        setValue('construccion', infraestructura.construccion);
        setValue('area', infraestructura.area);
        setValue('valor', infraestructura.valor);
        setValue('area', infraestructura.area);
        setValue('edad_castigo', infraestructura.edad_castigo);
        setValue('mantenimiento_castigo', infraestructura.mantenimiento_castigo);
        setValue('proceso_constructivo_castigo', infraestructura.proceso_constructivo_castigo);
        setValue('deseabilidad_castigo', infraestructura.deseabilidad_castigo);
        // eslint-disable-next-line
    }, [infraestructura])

    useEffect(() => {
        let valor = Number.parseFloat(watchItems.valor);
        let edad = Number.parseFloat(watchItems.edad_castigo);
        let mantenimiento = Number.parseFloat(watchItems.mantenimiento_castigo);
        let proceso_constructivo = Number.parseFloat(watchItems.proceso_constructivo_castigo);
        let deseabilidad = Number.parseFloat(watchItems.deseabilidad_castigo);

        setValue('valorBancario', (valor * edad * mantenimiento * proceso_constructivo * deseabilidad * 0.7).toFixed(2));


        const cociente = Math.floor(Number.parseFloat(watchItems.valorBancario) / 1);
        const decenaCercana = cociente * 1;

        setValue('valorAjustado', (decenaCercana).toFixed(2));

        const valorTotal = Number.parseFloat(watchItems.valorAjustado) * Number.parseFloat(watchItems.area);
        setValue('valorTotal', valorTotal.toFixed(2));


        // eslint-disable-next-line
    }, [watchItems.valor, watchItems.edad_castigo, watchItems.mantenimiento_castigo, watchItems.proceso_constructivo_castigo, watchItems.deseabilidad_castigo, watchItems.valorBancario, watchItems.valorAjustado])

    // const separator = (numb) => {
    //     if (numb) {
    //         var str = numb.toString().split(".");
    //         str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //         return str.join(".");
    //     } else {
    //         return 0.00
    //     }
    // }

    return (
        <div className='flex justify-center mt-4'>
            <div className='w-full max-w-7xl bg-gray-50 rounded-lg shadow-2xl p-6'>
                <div className=" mx-auto p-4">
                    <form onSubmit={handleSubmit((data) => questionModal('Editar', '¿Desea confirmar la edición de este registro?', () => editarInfraestructuraIndividual(infraestructura.id_infraestructura, data, decryptId(id), setShow3)))}>
                        {/* questionModal('Editar', '¿Desea editar el registro?', () => crearInfraestructuraIndividual(decryptId(id), data, setShow)) */}
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="my-2 w-full px-3 ">
                                <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">INFRAESTRUCTURA</h1>
                            </div>
                            <InputText nameLabel='Construcción' register={register} name="construccion" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. construcción 1" disabled={true} />
                            <InputDecimal nameLabel='Área m2' register={register} name="area" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 45.00" disabled={true} />
                            <InputDecimal nameLabel='Valor Q.' register={register} name="valor" message="El dato es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 1500" />
                            <InputDecimal nameLabel='Edad' register={register} name="edad_castigo" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.90" />
                            <InputDecimal nameLabel='Mantenimiento' register={register} name="mantenimiento_castigo" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 0.95" />
                            <InputDecimal nameLabel='Proceso constructivo' register={register} name="proceso_constructivo_castigo" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej.  0.95" />
                            <InputDecimal nameLabel='Deseabilidad' register={register} name="deseabilidad_castigo" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 1" />

                            <div className="my-2 w-full px-3 ">
                                <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">RESULTADOS</h1>
                            </div>
                            <InputDecimal nameLabel='Valor bancario Q.' register={register} name="valorBancario" message="El dato es requerido" position="md:w-1/2" errors={errors} Placeholder="Ej. 1" disabled={true} />
                            <InputDecimal nameLabel='Valor ajustado Q.' register={register} name="valorAjustado" message="El dato es requerido" position="md:w-1/2" errors={errors} Placeholder="Ej. 1" disabled={true} />

                            <InputDecimal nameLabel='Valor total Q.' register={register} name="valorTotal" message="El dato es requerido" position="md:w-1/4" errors={errors} Placeholder="Ej. 1" disabled={true} />

                            <div className="w-full mx-3 mt-1 text-center flex justify-end">
                                <NormalButton2 typeButton="submit" bgColor='bg-blue-900' bgText='text-white font-semibold' hoverColor='hover:bg-blue-800' dimension="mr-1 px-10 sm:px-6 py-2 rounded-md cursor-pointer" title='Editar' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div >
    )
}
