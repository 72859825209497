import { useForm } from "react-hook-form";
import { useAvaluoSlice, useRegistroPropiedadSlice } from "../../../../../../hooks";
import { useEffect } from "react";
import { InputDate, InputText, NormalButton, NormalButton2, SelectOption } from "../../../../../../ui";
import { departamentos, questionModal, successModal } from "../../../../../../utils";

const adquisicion1 = [{ adquisicion: "desmembracion" }, { adquisicion: "adjudicación" }, { adquisicion: "partición" }, { adquisicion: "titulación supletoria" }]
const adquisicion2 = [{ adquisicion: "compraventa" }, { adquisicion: "donación" }, { adquisicion: "partición" }, { adquisicion: "adjudicación" }]


export const EdicionRegistroFase1 = ({ edicion, setEdicion }) => {

    const { avaluoIndividual } = useAvaluoSlice();
    const { detalleCompletoRegistroPropiedad, editarRegistroPropiedad } = useRegistroPropiedadSlice();
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const activarEdicion = () => {
        if (edicion) {
            successModal('Edicion desactivada', 'center', 'info');
            setEdicion(false);
        } else {
            successModal('Edicion activada', 'center', 'warning');
            setEdicion(true);
        }
    }

    useEffect(() => {
        setValue('ub_registral', avaluoIndividual.ubicacion_legal);
        setValue('propiedad_de', avaluoIndividual.propietario);

        setValue('finca', detalleCompletoRegistroPropiedad.registro_propiedad.finca);
        setValue('folio', detalleCompletoRegistroPropiedad.registro_propiedad.folio);
        setValue('libro', detalleCompletoRegistroPropiedad.registro_propiedad.libro);
        setValue('departamento', detalleCompletoRegistroPropiedad.registro_propiedad.departamento);
        setValue('forma_adquisicion_1', detalleCompletoRegistroPropiedad.registro_propiedad.forma_adquisicion_1);
        setValue('finca_matriz', detalleCompletoRegistroPropiedad.registro_propiedad.finca_matriz);
        setValue('forma_adquisicion_2', detalleCompletoRegistroPropiedad.registro_propiedad.forma_adquisicion_2);
        setValue('fecha_inscripcion', detalleCompletoRegistroPropiedad.registro_propiedad.fecha_inscripcion);
        setValue('numero_inscripcion', detalleCompletoRegistroPropiedad.registro_propiedad.numero_inscripcion);
        setValue('fuente', detalleCompletoRegistroPropiedad.registro_propiedad.fuente);
        setValue('fecha_fuente', detalleCompletoRegistroPropiedad.registro_propiedad.fecha_fuente);
        setValue('plano', detalleCompletoRegistroPropiedad.registro_propiedad.plano);
        // eslint-disable-next-line
    }, [avaluoIndividual, detalleCompletoRegistroPropiedad]);

    return (
        <>
            <div className=" mx-auto p-4">
                <form onSubmit={handleSubmit((data) => editarRegistroPropiedad(detalleCompletoRegistroPropiedad.registro_propiedad.id_registro_propiedad, data, setEdicion))}>
                    <div className="flex flex-wrap -mx-3 mb-6">

                        <div className="w-full mx-3 mt-5 text-center flex justify-end">
                            <NormalButton funcion={() => questionModal(edicion ? 'Desactivar edición' : 'Activar edición', edicion ? '¿Desea desactivar el modo de edición?' : '¿Desea activar el modo de edición?', activarEdicion)} bgColor={edicion ? 'bg-yellow-700' : 'bg-green-700'} bgText='text-white font-semibold' hoverColor={edicion ? 'hover:bg-yellow-800' : 'hover:bg-green-800'} dimension="mr-1 px-1 sm:px-6 py-2 rounded-md cursor-pointer" title={edicion ? "Desactivar edición" : "Activar edición"} />
                            <NormalButton2 typeButton="submit" bgColor={edicion ? 'bg-blue-900  cursor-pointer' : 'bg-blue-300'} bgText='text-white font-semibold' hoverColor={edicion ? 'hover:bg-blue-800' : ''} dimension="mr-1 px-1 sm:px-6 py-2 rounded-md" title="Guardar" disabledButton={edicion ? false : true} />
                        </div>

                        <div className="mb-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Identificación registral</h1>
                        </div>
                        <InputText nameLabel='Finca' register={register} name="finca" message="La finca es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. 5997" disabled={edicion ? false : true} />
                        <InputText nameLabel='Folio' register={register} name="folio" message="El folio es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 510" disabled={edicion ? false : true} />
                        <InputText nameLabel='Libro' register={register} name="libro" message="El libro es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 110E" disabled={edicion ? false : true} />
                        <SelectOption nameLabel='Departamento' register={register} name='departamento' message='El departamento es requerido' errors={errors} array={departamentos} valueOption='departamento' nameOption='departamento' position="md:w-1/3" disabled={edicion ? false : true}/>

                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Ubicación registral</h1>
                        </div>
                        <InputText nameLabel='Ubicación registral' register={register} name="ub_registral" message="El departamento es requerido" errors={errors} Placeholder="Ej. Alta Verapaz." disabled={true} />

                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Origen registral</h1>
                        </div>
                        <SelectOption nameLabel='Forma de adquisición' register={register} name='forma_adquisicion_1' message='La forma de adquisición es requerida' errors={errors} array={adquisicion1} valueOption='adquisicion' nameOption='adquisicion' position="md:w-1/2" disabled={edicion ? false : true} />
                        <InputText nameLabel='Finca Matriz' register={register} name="finca_matriz" message="La finca matriz es requerida" position="md:w-1/2" errors={errors} Placeholder="Ej. Finca 144 Folio 172 Libro 19 de Primera Serie." disabled={edicion ? false : true} />

                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Titular registral</h1>
                        </div>
                        <InputText nameLabel='Propiedad de' register={register} name="propiedad_de" message="El departamento es requerido" errors={errors} Placeholder="Ej. Alta Verapaz." disabled={true} />
                        <SelectOption nameLabel='Forma de adquisición' register={register} name='forma_adquisicion_2' message='La forma de adquisición es requerida' position="md:w-1/3" errors={errors} array={adquisicion2} valueOption='adquisicion' nameOption='adquisicion' disabled={edicion ? false : true} F />
                        <InputDate nameLabel='Fecha de inscripción' register={register} name='fecha_inscripcion' message='La fecha es requerida' errors={errors} position="md:w-1/3" disabled={edicion ? false : true} />
                        <InputText nameLabel='Número de inscripción' register={register} name="numero_inscripcion" message="El número es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. 10" disabled={edicion ? false : true} />

                        <div className="my-2 w-full px-3 ">
                            <h1 className="text-xl font-bold mt-2 border-b-2 border-gray-200 text-gray-400">Fuente</h1>
                        </div>
                        <InputText nameLabel='Fuente' register={register} name="fuente" message="La fuente es requerida" position="md:w-1/3" errors={errors} Placeholder="Ej. Consulta electrónica" disabled={edicion ? false : true} />
                        <InputDate nameLabel='Fecha de la fuente' register={register} name='fecha_fuente' message='La fecha del acuerdo es requerida' errors={errors} position="md:w-1/3" disabled={edicion ? false : true} />
                        <InputText nameLabel='Plano' register={register} name="plano" message="El plano es requerido" position="md:w-1/3" errors={errors} Placeholder="Ej. Ing. Juan Armando" disabled={edicion ? false : true} />



                    </div>
                </form>
            </div>
        </>
    )
}
