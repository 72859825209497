import { useForm } from "react-hook-form";
import { InputText, SelectOption } from "../../../ui";
import { useAgencia, useAvaluoSlice, useColaborador } from "../../../hooks";
import { useEffect } from "react";

export const EditarAvaluo = ({ setShowEdit, setValue2 }) => {

    const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
    const { avaluoIndividual, editarAvaluoIndividual, obtenerTotalAvaluos, limpiarAvaluoInd } = useAvaluoSlice();
    const { agencia, obtenerTotalAgenciasEdicion } = useAgencia();
    const { colaborador, obtenerTotalColaboradoresFiltro } = useColaborador();
    let watchItem = watch();

    useEffect(() => {
        obtenerTotalAgenciasEdicion(setValue, avaluoIndividual.id_agencia);
        // eslint-disable-next-line
    }, [avaluoIndividual]);

    useEffect(() => {
        obtenerTotalColaboradoresFiltro(watchItem.id_agencia, setValue, avaluoIndividual.id_colaborador);
        // eslint-disable-next-line
    }, [avaluoIndividual, watchItem.id_agencia]);

    useEffect(() => {
        setValue('solicitante', avaluoIndividual.solicitante);
        setValue('propietario', avaluoIndividual.propietario);
        setValue('ubicacion_legal', avaluoIndividual.ubicacion_legal);
        setValue('ubicacion_inspeccion_campo', avaluoIndividual.ubicacion_inspeccion_campo);
        setValue('coordenada', avaluoIndividual.coordenada);
        // eslint-disable-next-line
    }, [avaluoIndividual]);

    const cerrar = () => {
        limpiarAvaluoInd();
        setShowEdit(false);
    }


    return (
        <>
            <div className='flex justify-center'>
                <div className="w-full max-w-4xl bg-white rounded-xl shadow-2xl px-6 py-3 mb-5 mt-10">
                    <div className='mb-6 text-gray-400 font-bold sm:text-2xl text-xl'>
                        <h1>
                            EDITAR INFORMACIÓN GENERAL DEL INMUEBLE VALUADO
                            <hr />
                        </h1>
                    </div>
                    <form onSubmit={handleSubmit((data) => editarAvaluoIndividual(avaluoIndividual.id_avaluo, data, setShowEdit, setValue2, obtenerTotalAvaluos, watchItem))}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <InputText nameLabel='Nombre del solicitante' register={register} name="solicitante" message="El solicitante es requerido" errors={errors} Placeholder="Ej. Pedro González Hernandez" />
                            <InputText nameLabel='Nombre(s) de propietario(s)' register={register} name="propietario" message="El propietario es requerido" errors={errors} Placeholder="Ej. Juan Fernández, Fredy Ortiz" />
                            <InputText nameLabel='Ubicación Legal' register={register} name="ubicacion_legal" message="La ubiación es requerida" errors={errors} Placeholder="Ej. Terreno X, ubicado en el municipio de X, departamento XX." />
                            <InputText nameLabel='Ubicación según inspección realizada en campo' register={register} name="ubicacion_inspeccion_campo" message="La ubiación es requerida" errors={errors} Placeholder="Ej. Caserío X, ubicado en el municipio de X, departamento XX." />
                            <InputText nameLabel='Coordenada (Central)' register={register} name="coordenada" message="Las coordenadas son requeridas" position="md:w-1/2" errors={errors} Placeholder="Ej. 15.10°-89.73°" />
                            <SelectOption nameLabel='Agencia' register={register} name='id_agencia' message='El valor es requerido' errors={errors} array={agencia} valueOption='id_agencia' nameOption='agencia' position="md:w-1/2" />
                            <SelectOption nameLabel='Colaborador' register={register} name='id_colaborador' message='El valor es requerido' errors={errors} array={colaborador} valueOption='id_colaborador' nameOption='colaborador' position="md:w-1/2" />
                            <div className="w-full md:w-full px-3">
                                <div className="text-center flex">
                                    <input
                                        className="w-full mr-1 px-3 py-2 font-bold text-white bg-blue-900 rounded-md cursor-pointer hover:bg-blue-800 focus:outline-none focus:shadow-outline"
                                        type="submit"
                                        value='Editar'
                                    />
                                    <button type="button" onClick={cerrar} className="font-bold text-white w-full px-4 py-2 bg-red-700 rounded-md cursor-pointer hover:bg-red-600 focus:outline-none focus:shadow-outline">
                                        Cancelar
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>

        </>
    )
}
