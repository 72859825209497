import { useAvaluoSlice } from "../../../hooks";

export const InformeAvaluo = ({ setShow }) => {

    const { informe } = useAvaluoSlice();

    const fechaMembrete = (fecha) => {
        let meses =
            [
                { id: '01', mes: 'Enero' },
                { id: '02', mes: 'Febrero' },
                { id: '03', mes: 'Marzo' },
                { id: '04', mes: 'Abril' },
                { id: '05', mes: 'Mayo' },
                { id: '06', mes: 'Junio' },
                { id: '07', mes: 'Julio' },
                { id: '08', mes: 'Agosto' },
                { id: '09', mes: 'Septiembre' },
                { id: '10', mes: 'Octubre' },
                { id: '11', mes: 'Noviembre' },
                { id: '12', mes: 'Diciembre' }
            ];

        let fecha2 = fecha.split('-');
        for (let i = 0; i < meses.length; i++) {
            if (meses[i].id === fecha2[1]) {
                fecha2[1] = meses[i].mes;
            }
        }

        return `Cobán, Alta Verapaz, ${fecha2[2]} de ${fecha2[1]} de ${fecha2[0]}`;
    }

    const fechaConversion = (fecha) => {
        let SaveFec = fecha.split('-');
        let prueba = new Date(SaveFec[0], (SaveFec[1] - 1), SaveFec[2]).toLocaleDateString('es-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
        let prueba2 = prueba.split(" ");
        return `${prueba2[1]} de ${prueba2[3]} de ${prueba2[5]}`;
    }

    const separator = (numb) => {
        if (numb) {
            var str = numb.toString().split(".");
            str[0] = str[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return str.join(".");
        } else {
            return 0.00
        }
    }

    const sumaDesmembraciones = (desmembracion) => {
        let resultado = 0;
        if (desmembracion) {
            for (let i = 0; i < desmembracion.length; i++) {
                resultado = resultado + Number.parseFloat(desmembracion[i].area_desmembracion);
            }
            return resultado.toFixed(2);
        } else {
            return 0;
        }
    }

    const areaRegistralActual = (desmembracion, area_original) => {
        let resultado = Number.parseFloat(area_original) - sumaDesmembraciones(desmembracion);
        return resultado.toFixed(2);
    }

    const areaLegal = (registro_propiedad) => {
        let areaLegalTotal = 0;
        for (let i = 0; i < registro_propiedad.length; i++) {
            areaLegalTotal = areaLegalTotal + Number.parseFloat(areaRegistralActual(registro_propiedad[i].desmembracion, registro_propiedad[i].detalle_desmembracion[0].area_original));
        }
        return areaLegalTotal.toFixed(2);
    }

    const metrosAmanzanas = (valor) => {
        let manzana = valor / 6987.37;
        return manzana.toFixed(2);
    }

    const diferencia = (area_campo, registro_propiedad) => {
        let resultado = 0;

        if (Number.parseFloat(area_campo) > areaLegal(registro_propiedad)) {
            resultado = Number.parseFloat(area_campo) - areaLegal(registro_propiedad);
        } else if (Number.parseFloat(area_campo) < areaLegal(registro_propiedad)) {
            resultado =  areaLegal(registro_propiedad) - Number.parseFloat(area_campo);
        }

        return resultado.toFixed(2);
    }

    const porcentaje = (area_campo, registro_propiedad) => {
        let resultado = (diferencia(area_campo, registro_propiedad) * 100) / areaLegal(registro_propiedad);
        return resultado.toFixed(2);
    }

    const plusvalia = (plusvaliasMinusvalias) => {
        let plusvalia = [];
        for (let i = 0; i < plusvaliasMinusvalias.length; i++) {
            if (plusvaliasMinusvalias[i].tipo === 'Plusvalía') {
                plusvalia.push({
                    comentario: plusvaliasMinusvalias[i].comentario
                })
            }
        }
        return plusvalia;
    }

    const minusvalia = (plusvaliasMinusvalias) => {
        let minusvalia = [];
        for (let i = 0; i < plusvaliasMinusvalias.length; i++) {
            if (plusvaliasMinusvalias[i].tipo === 'Minusvalía') {
                minusvalia.push({
                    comentario: plusvaliasMinusvalias[i].comentario
                })
            }
        }
        return minusvalia;
    }

    const subrayado = (color) => {

        if (color === 'Sin color') {
            return 'bg-inherit'
        } else if (color === 'Rojo') {
            return 'bg-red-500'
        } else if (color === 'Azul') {
            return 'bg-blue-500'
        } else if (color === 'Verde') {
            return 'bg-green-500'
        } else if (color === 'Celeste') {
            return 'bg-sky-500'
        } else {
            return ''
        }
    }

    const metroManzana = (detalle_inmueble, areaValuada) => {

        if (Number.parseFloat(detalle_inmueble[0].area_campo) > 6987.37) {
            let area = (Number.parseFloat(detalle_inmueble[0].area_campo) / 6987.37).toFixed(2);
            let areaValuada2 = Number.parseFloat(areaValuada);

            if (area >= areaValuada2) {
                return 'Mz';
            } else {
                return 'm2';
            }
        } else {
            return 'm2';
        }
    }

    const valorBase = (descripcion_referencial, detalle_inmueble_total, valor_inmueble_terreno) => {
        let valorBase = 0;
        let contador = 0;
        if (Number.parseFloat(detalle_inmueble_total) >= 6987.37) {
            let area = (Number.parseFloat(detalle_inmueble_total) / 6987.37).toFixed(2);

            if (area >= Number.parseFloat(valor_inmueble_terreno.area)) {
                for (let i = 0; i < descripcion_referencial.length; i++) {
                    if (descripcion_referencial[i].dimensional === 'Mz') {
                        valorBase = valorBase + Number.parseFloat(descripcion_referencial[i].valor);
                        contador = contador + 1
                    }
                }
            } else {
                for (let i = 0; i < descripcion_referencial.length; i++) {
                    if (descripcion_referencial[i].dimensional === 'm2') {
                        valorBase = valorBase + Number.parseFloat(descripcion_referencial[i].valor);
                        contador = contador + 1
                    }
                }
            }
        } else {
            for (let i = 0; i < descripcion_referencial.length; i++) {
                if (descripcion_referencial[i].dimensional === 'm2') {
                    valorBase = valorBase + Number.parseFloat(descripcion_referencial[i].valor);
                    contador = contador + 1
                }
            }
        }

        valorBase = valorBase / contador;
        return valorBase.toFixed(2);
    }

    const obtenerValorTerrenoPorMetro = (descripcion_referencial, valor_inmueble_terreno, factor_intangible, factor_riesgo, detalle_inmueble_total) => {
        let inmueble = Number.parseFloat(valor_inmueble_terreno.lote_interior) * Number.parseFloat(valor_inmueble_terreno.relacion_frente_fondo) * Number.parseFloat(valor_inmueble_terreno.forma) * Number.parseFloat(valor_inmueble_terreno.pendiente) * Number.parseFloat(valor_inmueble_terreno.esquina) * Number.parseFloat(valor_inmueble_terreno.bajo_sobre_nivel) * Number.parseFloat(valor_inmueble_terreno.uso_inmueble);
        let intangible = Number.parseFloat(factor_intangible[0].luz) * Number.parseFloat(factor_intangible[0].agua) * Number.parseFloat(factor_intangible[0].drenaje) * Number.parseFloat(factor_intangible[0].condicion_acceso) * Number.parseFloat(factor_intangible[0].contaminacion) * Number.parseFloat(factor_intangible[0].mejoras) * Number.parseFloat(factor_intangible[0].deseabilidad) * Number.parseFloat(factor_intangible[0].otro);
        let riesgo = Number.parseFloat(factor_riesgo[0].traslado_dominio) * Number.parseFloat(factor_riesgo[0].liquidez_registral) * Number.parseFloat(factor_riesgo[0].area_colindantes_medidas) * Number.parseFloat(factor_riesgo[0].derecho_posesion) * Number.parseFloat(factor_riesgo[0].traslape_finca) * Number.parseFloat(factor_riesgo[0].acceso_servidumbre) * Number.parseFloat(factor_riesgo[0].predio_inconexo) * Number.parseFloat(factor_riesgo[0].predio_enclavado) * Number.parseFloat(factor_riesgo[0].traslape_construccion) * Number.parseFloat(factor_riesgo[0].uso_posesion) * Number.parseFloat(factor_riesgo[0].construccion_uso_especifico) * Number.parseFloat(factor_riesgo[0].ubicacion) * Number.parseFloat(factor_riesgo[0].plano) * Number.parseFloat(factor_riesgo[0].otros);

        let preResultado = inmueble * intangible * riesgo * 0.7 * Number.parseFloat(valorBase(descripcion_referencial, detalle_inmueble_total, valor_inmueble_terreno));
        let cociente = Math.floor(preResultado / 1);
        let resultado = cociente * 1;
        return resultado.toFixed(2);
    }

    const obtenerValorTerreno = (descripcion_referencial, valor_inmueble_terreno, factor_intangible, factor_riesgo, detalle_inmueble_total) => {
        let resultado = Number.parseFloat(valor_inmueble_terreno.area) * Number.parseFloat(obtenerValorTerrenoPorMetro(descripcion_referencial, valor_inmueble_terreno, factor_intangible, factor_riesgo, detalle_inmueble_total));
        return resultado.toFixed(2);
    }

    const valorSumatoriaTotalTerrenos = (descripcion_referencial, valor_inmueble_terreno, detalle_inmueble_total) => {
        let total = 0
        for (let i = 0; i < valor_inmueble_terreno.length; i++) {
            total = total + Number.parseFloat(obtenerValorTerreno(descripcion_referencial, valor_inmueble_terreno[i].valor_inmueble_terreno, valor_inmueble_terreno[i].factor_intangible, valor_inmueble_terreno[i].factor_riesgo, detalle_inmueble_total));
        }
        return total.toFixed(2);
    }

    const convertirTextoANumero = (texto) => {
        let valor = Number.parseFloat(texto);
        return valor.toFixed(2);
    }

    const obtenerValorInfraestructuraPorMetro = (valor, edad_castigo, mantenimiento_castigo, proceso_constructivo_castigo, deseabilidad_castigo) => {
        valor = Number.parseFloat(valor);
        edad_castigo = Number.parseFloat(edad_castigo);
        mantenimiento_castigo = Number.parseFloat(mantenimiento_castigo);
        proceso_constructivo_castigo = Number.parseFloat(proceso_constructivo_castigo);
        deseabilidad_castigo = Number.parseFloat(deseabilidad_castigo);

        let preResultado = (edad_castigo * mantenimiento_castigo * proceso_constructivo_castigo * deseabilidad_castigo * 0.7 * valor).toFixed(2);
        let cociente = Math.floor(preResultado / 1);
        let resultado = cociente * 1;
        return resultado.toFixed(2);
    }

    const obtenerValorInfraestructura = (area, valor, edad_castigo, mantenimiento_castigo, proceso_constructivo_castigo, deseabilidad_castigo) => {
        area = Number.parseFloat(area);
        let resultado = area * obtenerValorInfraestructuraPorMetro(valor, edad_castigo, mantenimiento_castigo, proceso_constructivo_castigo, deseabilidad_castigo);
        return resultado.toFixed(2);
    }

    const valorSumatoriaTotalInfraestructura = (infraestructura) => {
        let total = 0
        for (let i = 0; i < infraestructura.length; i++) {
            total = total + Number.parseFloat(obtenerValorInfraestructura(infraestructura[i].area, infraestructura[i].valor, infraestructura[i].edad_castigo, infraestructura[i].mantenimiento_castigo, infraestructura[i].proceso_constructivo_castigo, infraestructura[i].deseabilidad_castigo));
        }
        return total.toFixed(2);
    }

    const precioTotal = (descripcion_referencial, valor_inmueble_terreno, infraestructura, detalle_inmueble_total) => {
        let resultado = Number.parseFloat(valorSumatoriaTotalTerrenos(descripcion_referencial, valor_inmueble_terreno, detalle_inmueble_total)) + Number.parseFloat(valorSumatoriaTotalInfraestructura(infraestructura));

        return resultado.toFixed(2);
    }

    const dimensionesImagen = (item) => {
        if (item.anexo.dimension === '1/1') {
            return 'h-full w-full'
        } else if (item.anexo.dimension === '1/2') {
            return 'h-1/2 w-full'
        } else {
            return 'h-1/2 w-1/2'
        }
    }


    return (
        <div className="bg-gray-100 mt-4 p-5 flex justify-center items-center rounded-xl font-arial">
            <div className="bg-white rounded-md shadow-md m-4 pt-10 w-3/4">

                <div className='text-gray-400 font-bold text-4xl mx-12 mb-10'>
                    <h1>
                        PREVISUALIZACIÓN
                    </h1>
                </div>

                <div className="mx-20 ">
                    <p className="w-full text-right">{informe.avaluo.fecha_autorizado === null || informe.avaluo.fecha_autorizado === "" ? null : fechaMembrete(informe.avaluo.fecha_autorizado)}</p>


                    <p className="text-[12pt]">Hector Garcia
                        <br /> Jefe de Avalúos e Infraestructura
                        <br /> Cooperativa Cobán es MICOOPE.
                        <br />
                        <br /> Reciba usted un cordial saludo, de acuerdo con el avalúo solicitado se presenta en este documento los valores dados al inmueble con los siguientes datos.
                    </p>
                </div>

                {/* INFORMACION GENERAL DEL INMUEBLE */}
                <div className="text-[12pt] tracking-tight mt-8 mx-12">
                    <table className="default border-collapse border border-black w-full">
                        <thead>
                            <tr className="border border-black w-full">
                                <th colSpan="2" className="bg-[#BCD4EC] border border-black">
                                    <p className="text-[12pt]">INFORMACIÓN GENERAL DEL INMUEBLE VALUADO</p>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className=" border border-black w-full">
                                <td className="bg-[#BCD4EC] border border-black pl-2 ">Nombre del solicitante:</td>
                                <td className="font-semibold border border-black pl-2">{informe.avaluo.solicitante}</td>
                            </tr>

                            <tr>
                                <td className="bg-[#BCD4EC] border border-black pl-2">Nombre(s) de propietario(s):</td>
                                <td className="font-semibold border border-black pl-2">{informe.avaluo.propietario}</td>
                            </tr>

                            <tr>
                                <td colSpan="2" className="bg-[#BCD4EC] border border-black w-full pl-2 text-center">IDENTIFICACION REGISTRAL</td>
                            </tr>

                            {
                                informe.registro_propiedad.map((item, index) => (
                                    <tr key={index}>
                                        <td colSpan="2" className="font-semibold border border-black w-full pl-2 text-center">
                                            Finca {item.registro_propiedad.finca} Folio {item.registro_propiedad.folio} Libro {item.registro_propiedad.libro} de {item.registro_propiedad.departamento}.
                                            <br />
                                        </td>
                                    </tr>
                                ))
                            }

                            <tr>
                                <td colSpan="2" className="bg-[#BCD4EC] border border-black w-full text-center">UBICACIÓN LEGAL</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="border border-black w-full text-center">{informe.avaluo.ubicacion_legal}</td>
                            </tr>

                            <tr>
                                <td colSpan="2" className="bg-[#BCD4EC] border border-black w-full text-center">SEGÚN INSPECCIÓN REALIZADA EN CAMPO</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="border border-black w-full text-center">{informe.avaluo.ubicacion_inspeccion_campo}</td>
                            </tr>

                            <tr>
                                <td colSpan="2" className="bg-[#BCD4EC] border border-black w-full text-center">COORDENADA (CENTRAL)</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="border border-black w-full text-center">{informe.avaluo.coordenada}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* INFORMACION DEL REGISTRO DE LA PROPIEDAD */}
                {
                    informe.registro_propiedad.map((item, index) => (
                        <div className="text-[12pt] tracking-tight mt-12 mx-12">
                            <table className="default border-collapse border border-black w-full">
                                <tbody>

                                    <tr className="border border-black w-full">
                                        <th colSpan="7" className="bg-[#BCD4EC] border border-black">
                                            <p className="text-[12pt]">INFORMACIÓN DEL REGISTRO DE LA PROPIEDAD</p>
                                        </th>
                                    </tr>

                                    {/* IDENTRIFICACION REGISTRAL */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black w-full pl-2">Identificación registral:</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="7" className="font-semibold border border-black w-full pl-2">Finca {item.registro_propiedad.finca} Folio {item.registro_propiedad.folio} Libro {item.registro_propiedad.libro} de {item.registro_propiedad.departamento}</td>
                                    </tr>

                                    {/* UBICACION REGISTRAL */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black w-full pl-2">Ubicación Registral:</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="7" className="border border-black w-full pl-2">{informe.avaluo.ubicacion_legal}</td>
                                    </tr>

                                    {/* ORIGEN REGISTRAL */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black w-full pl-2">Origen registral:</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="bg-[#BCD4EC] border border-black pl-2 ">Forma de adquisición </td>
                                        <td colSpan="5" className="border border-black pl-2">{item.registro_propiedad.forma_adquisicion_1} </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="bg-[#BCD4EC] border border-black pl-2 ">Finca Matriz</td>
                                        <td colSpan="5" className="font-semibold border border-black  pl-2">{item.registro_propiedad.finca_matriz}</td>
                                    </tr>

                                    {/* TITULAR REGISTRAL */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black pl-2">Titular registral:</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="bg-[#BCD4EC] border border-black pl-2 ">Propiedad de:</td>
                                        <td colSpan="5" className="font-semibold border border-black pl-2">{informe.avaluo.propietario}</td>
                                    </tr>
                                    <tr className="border border-black w-full">
                                        <td colSpan="2" className="bg-[#BCD4EC] border border-black pl-2 ">Forma de adquisición:</td>
                                        <td colSpan="5" className="border border-black pl-2">{item.registro_propiedad.forma_adquisicion_2} </td>
                                    </tr>
                                    <tr className="border border-black w-full">
                                        <td colSpan="2" className="bg-[#BCD4EC] border border-black pl-2 ">Fecha de inscripción:</td>
                                        <td colSpan="5" className="border border-black pl-2">{fechaConversion(item.registro_propiedad.fecha_inscripcion)}</td>
                                    </tr>
                                    <tr className="border border-black w-full">
                                        <td colSpan="2" className="bg-[#BCD4EC] border border-black pl-2 ">No. de inscripción:</td>
                                        <td colSpan="5" className="border border-black pl-2">{item.registro_propiedad.numero_inscripcion}</td>
                                    </tr>

                                    {/* GRAVAMENES, ANOTACIONES Y LIMITACIONES */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black pl-2">Gravámenes, Anotaciones y Limitaciones.</td>
                                    </tr>

                                    {/* GRAVAMENES */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black  pl-2">Gravámenes vigentes:</td>
                                    </tr>
                                    <tr>
                                        {
                                            item.gravamen.length > 0 ?
                                                <td colSpan="7" className="bg-[#BCD4EC] border border-black  pl-2">{item.gravamen[0].numero_gravamen}</td>
                                                :
                                                <td colSpan="7" className="bg-[#BCD4EC] border border-black  pl-2">---</td>
                                        }
                                    </tr>

                                    {
                                        item.gravamen.length > 0 ?
                                            item.gravamen.map((item, index) =>
                                                <>
                                                    <tr>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2 ">Deudor:</td>
                                                        <td colSpan="6" className="font-semibold border border-black pl-2 text-center">{item.deudor}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2 ">Acreedor:</td>
                                                        <td colSpan="6" className="font-semibold border border-black text-center">{item.acreedor}</td>
                                                    </tr>
                                                </>
                                            )
                                            :
                                            <>
                                                <tr>
                                                    <td className="bg-[#BCD4EC] border border-black pl-2 ">Deudor:</td>
                                                    <td colSpan="6" className="border border-black pl-2 text-center">-------------------------------</td>
                                                </tr>
                                                <tr>
                                                    <td className="bg-[#BCD4EC] border border-black pl-2 ">Acreedor:</td>
                                                    <td colSpan="6" className=" border border-black text-center">-------------------------------</td>
                                                </tr>
                                            </>
                                    }

                                    {/* ANOTACIONES */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black pl-2">Anotaciones vigentes:</td>
                                    </tr>

                                    <tr>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Letra</td>
                                        <td colSpan="6" className="bg-[#BCD4EC] border border-black text-center">Descripción</td>
                                    </tr>

                                    {
                                        item.anotacion.length > 0 ?
                                            item.anotacion.map((item, index) =>
                                                <tr key={index}>
                                                    <td className="border border-black text-center">{item.letra}</td>
                                                    <td colSpan="6" className="border border-black text-center">{item.descripcion}</td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td className="border border-black text-center">---------</td>
                                                <td colSpan="6" className="border border-black text-center">-------------------------------</td>
                                            </tr>
                                    }

                                    {/* LIMITACIONES */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black  pl-2">Limitaciones:</td>
                                    </tr>

                                    <tr>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Número</td>
                                        <td colSpan="6" className="bg-[#BCD4EC] border border-black text-center">Descripción</td>
                                    </tr>

                                    {
                                        item.limitacion.length > 0 ?
                                            item.limitacion.map((item, index) =>
                                                <tr key={index}>
                                                    <td className="border border-black pl-2 text-center">{item.numero_limitacion}</td>
                                                    <td colSpan="6" className="border border-black pl-2 text-center">{item.descripcion}</td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td className="border border-black pl-2 text-center">---------</td>
                                                <td colSpan="6" className="border border-black pl-2 text-center">-------------------------------</td>
                                            </tr>
                                    }

                                    {/* MEDIDAS Y COLINDANCIAS */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black  pl-2">Medidas y colindancias:</td>
                                    </tr>

                                    <tr>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Linderos</td>
                                        <td colSpan="6" className="bg-[#BCD4EC] border border-black pl-2">Colindancias Registrales</td>
                                    </tr>

                                    {
                                        item.colindancia.map((item, index) =>
                                            <tr key={index}>
                                                <td className="border border-black pl-2">{item.lindero}</td>
                                                <td colSpan="6" className="border border-black pl-2">{item.colindancia_registral}</td>
                                            </tr>
                                        )
                                    }

                                    {/* AREA REGISTRAL ACTUAL */}
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black  pl-2">Área registral actual:</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black text-center">Desmembraciones</td>
                                    </tr>

                                    <tr>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">No.</td>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Finca</td>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Folio</td>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Libro</td>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Departamento</td>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Fecha</td>
                                        <td className="bg-[#BCD4EC] border border-black  pl-2">Área (m2)</td>
                                    </tr>

                                    {
                                        item.desmembracion.length > 0 ?
                                            item.desmembracion.map((item, index) =>
                                                <tr key={index}>
                                                    <td className="border border-black pl-2">{item.numero_desmembracion}</td>
                                                    <td className="border border-black pl-2">{item.finca}</td>
                                                    <td className="border border-black pl-2">{item.folio}</td>
                                                    <td className="border border-black pl-2">{item.libro}</td>
                                                    <td className="border border-black pl-2">{item.departamento}</td>
                                                    <td className="border border-black pl-2">{item.fecha}</td>
                                                    <td className="border border-black pl-2">{item.area_desmembracion}</td>
                                                </tr>
                                            )
                                            :
                                            <tr>
                                                <td className="border border-black text-center">--------</td>
                                                <td className="border border-black text-center">--------</td>
                                                <td className="border border-black text-center">--------</td>
                                                <td className="border border-black text-center">--------</td>
                                                <td className="border border-black text-center">--------</td>
                                                <td className="border border-black text-center">--------</td>
                                                <td className="border border-black text-center">--------</td>
                                            </tr>
                                    }

                                    <tr>
                                        <td colSpan="3" className="bg-[#BCD4EC] border border-black pl-2 ">Área original (1ra de dominio)</td>
                                        <td colSpan="2" className="bg-[#BCD4EC] border border-black pl-2 ">Total, desmembraciones</td>
                                        <td colSpan="2" className="bg-[#BCD4EC] border border-black pl-2 ">Área registral actual </td>
                                    </tr>

                                    <tr>
                                        <td colSpan="3" className="border border-black text-center">{separator(item.detalle_desmembracion[0].area_original)}</td>
                                        <td colSpan="2" className="border border-black text-center">{separator(sumaDesmembraciones(item.desmembracion))}</td>
                                        <td colSpan="2" className="border border-black text-center">{separator(areaRegistralActual(item.desmembracion, item.detalle_desmembracion[0].area_original))}</td>
                                    </tr>

                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black pl-2">Fuente</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="border border-black pl-2">{item.registro_propiedad.fuente}</td>
                                        <td colSpan="3" className="border border-black pl-2">{fechaConversion(item.registro_propiedad.fecha_fuente)}</td>
                                    </tr>

                                    <tr>
                                        <td colSpan="7" className="bg-[#BCD4EC] border border-black pl-2 ">Plano</td>
                                    </tr>

                                    <tr>
                                        <td colSpan="7" className="border border-black pl-2">{item.registro_propiedad.plano}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ))
                }

                {/* DETALLES DEL INMUEBLE */}
                <div className=" mt-8 mb-2 mx-12">
                    <span className="font-semibold">
                        1. DETALLES DEL INMUEBLE:
                    </span>
                </div>

                <div className="text-[12pt] tracking-tight mb-8 mx-12">
                    <table className="default border-collapse border border-black w-full">
                        <tbody>
                            <tr className="border border-black w-full">
                                <td colSpan="2" className="bg-[#BCD4EC] border border-black text-center">Descripción</td>
                                <td colSpan="2" className="bg-[#BCD4EC] border border-black text-center">m2</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="border border-black text-center">Área legal</td>
                                <td colSpan="2" className="border border-black text-center">{separator(areaLegal(informe.registro_propiedad))} m2 / {metrosAmanzanas(areaLegal(informe.registro_propiedad))} Mz</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="border border-black text-center">Área según plano</td>
                                <td colSpan="2" className="border border-black text-center">{separator(informe.detalle_inmueble[0].area_plano)} m2 / {metrosAmanzanas(informe.detalle_inmueble[0].area_plano)} Mz</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className="border border-black text-center">Área verificada en campo</td>
                                <td colSpan="2" className="border border-black text-center">{separator(informe.detalle_inmueble[0].area_campo)} m2 / {metrosAmanzanas(informe.detalle_inmueble[0].area_campo)} Mz</td>
                            </tr>

                            <tr>
                                <td colSpan="4" className="bg-[#BCD4EC] border border-black text-center">Comparación de áreas</td>
                            </tr>
                            <tr>
                                <td className="bg-[#BCD4EC] border border-black text-center">Área Legal</td>
                                <td className="bg-[#BCD4EC] border border-black text-center">Área Física</td>
                                <td className="bg-[#BCD4EC] border border-black text-center">Diferencia</td>
                                <td className="bg-[#BCD4EC] border border-black text-center">%</td>
                            </tr>

                            <tr>
                                <td className="border border-black text-center">{separator(areaLegal(informe.registro_propiedad))} m2 / {metrosAmanzanas(areaLegal(informe.registro_propiedad))} Mz</td>
                                <td className="border border-black text-center">{separator(informe.detalle_inmueble[0].area_campo)} m2 / {metrosAmanzanas(informe.detalle_inmueble[0].area_campo)} Mz</td>
                                <td className="border border-black text-center">{diferencia(informe.detalle_inmueble[0].area_campo, informe.registro_propiedad)} m2</td>
                                <td className="border border-black text-center">{porcentaje(informe.detalle_inmueble[0].area_campo, informe.registro_propiedad)}</td>
                            </tr>

                            <tr>
                                <td colSpan="4" className="bg-[#BCD4EC] border border-black text-center">Características Físicas:</td>
                            </tr>

                            <tr>
                                <td className="bg-[#BCD4EC] border border-black text-center">Forma</td>
                                <td className="bg-[#BCD4EC] border border-black text-center">Uso principal</td>
                                <td colSpan="2" className="bg-[#BCD4EC] border border-black text-center">Topografía</td>
                            </tr>

                            <tr>
                                <td className="border border-black text-center">{informe.detalle_inmueble[0].forma}</td>
                                <td className="border border-black text-center">{informe.detalle_inmueble[0].uso_principal}</td>
                                <td colSpan="2" className="border border-black text-center">{informe.detalle_inmueble[0].topografia}</td>
                            </tr>

                            <tr>
                                <td colSpan="4" className="bg-[#BCD4EC] border border-black text-center">Servicios públicos instalados</td>
                            </tr>

                            <tr>
                                <td colSpan="3" className="bg-[#BCD4EC] border border-black text-center">Servicio de agua entubada</td>
                                <td className="border border-black text-center">{informe.detalle_inmueble[0].servicio_agua_entubada}</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="bg-[#BCD4EC] border border-black text-center">Energía eléctrica</td>
                                <td className="border border-black text-center">{informe.detalle_inmueble[0].energia_electrica}</td>
                            </tr>
                            <tr>
                                <td colSpan="3" className="bg-[#BCD4EC] border border-black text-center">Drenajes</td>
                                <td className="border border-black text-center">{informe.detalle_inmueble[0].drenaje}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                {/* INFRAESTRUCTURA */}
                <div className=" mt-8 mb-2 mx-12">
                    <span className="font-semibold">
                        2.INFRAESTRUCTURA:
                    </span>
                </div>

                {
                    informe.infraestructura.length > 0 ?
                        informe.infraestructura.map((item, index) =>
                            <div className="text-[12pt] tracking-tight mb-8 mx-12">
                                <table className="default border-collapse border border-black w-full">
                                    <tbody>

                                        <tr>
                                            <td colSpan="2" className="font-semibold bg-[#BCD4EC] border border-black pl-2 text-center">{item.construccion}</td>
                                            <td className="font-semibold bg-[#BCD4EC] border border-black pl-2 text-center">Área</td>
                                            <td className="font-semibold bg-[#BCD4EC] border border-black pl-2 text-center">{item.area} m2</td>
                                        </tr>

                                        <tr>
                                            <td className="bg-[#BCD4EC] border border-black pl-2">Edad</td>
                                            <td className="border border-black pl-2">{item.edad}</td>
                                            <td className="bg-[#BCD4EC] border border-black pl-2">Mantenimiento</td>
                                            <td className="border border-black pl-2">{item.mantenimiento}</td>
                                        </tr>
                                        <tr>
                                            {
                                                item.techo ?
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Techo/Estructura</td>
                                                        <td className="border border-black pl-2">{item.techo}</td>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Revestimiento</td>
                                                        <td className="border border-black pl-2">{item.revestimiento_techo}</td>
                                                    </>
                                                    :
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Techo/Estructura</td>
                                                        <td className="border border-black pl-2">--------</td>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Revestimiento</td>
                                                        <td className="border border-black pl-2">--------</td>
                                                    </>
                                            }
                                        </tr>
                                        <tr>
                                            <td className="bg-[#BCD4EC] border border-black pl-2">Pared</td>
                                            <td className="border border-black pl-2">{item.pared}</td>
                                            <td className="bg-[#BCD4EC] border border-black pl-2">Revestimiento</td>
                                            <td className="border border-black pl-2">{item.revestimiento_pared}</td>
                                        </tr>
                                        <tr>
                                            {
                                                item.piso ?
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Piso</td>
                                                        <td className="border border-black pl-2">{item.piso}</td>
                                                    </>
                                                    :
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Piso</td>
                                                        <td className="border border-black pl-2">--------</td>
                                                    </>

                                            }
                                            {
                                                item.puerta ?
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Puertas</td>
                                                        <td className="border border-black pl-2">{item.puerta}</td>
                                                    </>
                                                    :
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Puertas</td>
                                                        <td className="border border-black pl-2">--------</td>
                                                    </>
                                            }
                                        </tr>
                                        <tr>
                                            {
                                                item.ventana ?
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Ventanas</td>
                                                        <td className="border border-black pl-2">{item.ventana}</td>
                                                    </>
                                                    :
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Ventanas</td>
                                                        <td className="border border-black pl-2">--------</td>
                                                    </>
                                            }
                                            {
                                                item.instalacion_electrica ?
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Instalación Eléctrica</td>
                                                        <td className="border border-black pl-2">{item.instalacion_electrica}</td>
                                                    </>
                                                    :
                                                    <>
                                                        <td className="bg-[#BCD4EC] border border-black pl-2">Instalación Eléctrica</td>
                                                        <td className="border border-black pl-2">--------</td>
                                                    </>
                                            }
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        )
                        :
                        <div className="mb-8 mx-12">
                            <span className="font-semibold">
                                No posee
                            </span>
                        </div>
                }

                {/* PLUSVALIAS Y MINUSVALIAS */}
                <div className=" mt-8 mb-2 mx-12">
                    <span className="font-semibold">
                        3. PLUSVALÍAS/MINUSVALÍAS
                    </span>
                </div>

                <div className="text-[12pt] tracking-tight mb-8 mx-12">
                    <table className="default border-collapse border border-black w-full">
                        <tbody>
                            <tr>
                                <td className="font-semibold bg-[#BCD4EC] border border-black text-center">Plusvalías</td>
                            </tr>

                            {
                                plusvalia(informe.plusvaliasMinusvalias).map((item, index) =>
                                    <tr key={index}>
                                        <td className="border border-black pl-2">{item.comentario}</td>
                                    </tr>
                                )
                            }

                            <tr>
                                <td className="font-semibold bg-[#BCD4EC] border border-black text-center">Minusvalías</td>
                            </tr>

                            {
                                minusvalia(informe.plusvaliasMinusvalias).map((item, index) =>
                                    <tr key={index}>
                                        <td className="border border-black pl-2">{item.comentario}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>

                {/* OBSERVACIONES */}
                <div className=" mt-8 mb-2 mx-12">
                    <span className="font-semibold">
                        4. OBSERVACIONES:
                    </span>
                </div>

                <div className="mx-16 mb-8">
                    {
                        informe.observaciones.map((item, index) =>
                            <p key={index} className="text-[12pt] text-justify font-semibold leading-tight mb-4">
                                <mark className={`${subrayado(item.color)}`}>
                                    - {item.observacion}
                                </mark>
                            </p>
                        )
                    }
                    <p className="text-[12pt] text-justify font-semibold leading-tight mb-4">
                        <mark className='bg-inherit'>
                            - El valor bancario del terreno es asignado de acuerdo con las referenciales obtenidos y la dinámica inmobiliaria actual del sector donde se ubica, considerando como parte integral del mismo, las mejoras realizadas para lograr su máximo aprovechamiento.
                        </mark>
                    </p>
                    <p className="text-[12pt] text-justify font-semibold leading-tight mb-4">
                        <mark className='bg-inherit'>
                            - El enfoque de la valoración que se presenta es de carácter bancario, que en consideración de que el inmueble valuado será objeto de garantía hipotecaria para respaldar una obligación crediticia, al valor unitario ajustado por factores propios y del entorno.
                        </mark>
                    </p>
                </div>


                {/* VALORES DEL INMUEBLE */}
                <div className=" mt-8 mb-2 mx-12">
                    <span className="font-semibold">
                        5. VALORES DEL INMUEBLE
                    </span>
                </div>

                <div className="mb-8 mx-12">
                    <table className="default border-collapse border border-black w-full">
                        <tbody>
                            {
                                informe.valor_inmueble_terreno.map((item, index) =>
                                    <>
                                        <tr className="border border-black w-full">
                                            <td className="bg-[#BCD4EC] border border-black text-center">Descripción</td>
                                            <td className="bg-[#BCD4EC] border border-black text-center">Área Física en {metroManzana(informe.detalle_inmueble, item.valor_inmueble_terreno.area)}</td>
                                            <td className="bg-[#BCD4EC] border border-black text-center">Valor Q. por {metroManzana(informe.detalle_inmueble, item.valor_inmueble_terreno.area)}</td>
                                            <td className="bg-[#BCD4EC] border border-black text-center">Valor Q.</td>
                                        </tr>


                                        <tr key={index}>
                                            <td className="bg-[#BCD4EC] border border-black text-center">Terreno</td>
                                            <td className="border border-black text-center">{item.valor_inmueble_terreno.area} {metroManzana(informe.detalle_inmueble, item.valor_inmueble_terreno.area)}</td>
                                            <td className="border border-black text-center">Q. {separator(obtenerValorTerrenoPorMetro(informe.descripcion_referencial, item.valor_inmueble_terreno, item.factor_intangible, item.factor_riesgo, informe.detalle_inmueble[0].area_campo))}</td>
                                            <td className="border border-black text-center">Q. {separator(obtenerValorTerreno(informe.descripcion_referencial, item.valor_inmueble_terreno, item.factor_intangible, item.factor_riesgo, informe.detalle_inmueble[0].area_campo))}</td>
                                        </tr>
                                    </>
                                )
                            }

                            {
                                informe.infraestructura.length > 0 ?
                                    <tr>
                                        <td colSpan="3" className="bg-[#BCD4EC] border border-black text-center">Total:</td>
                                        <td className="font-semibold bg-[#BCD4EC] border border-black text-center">Q. {separator(valorSumatoriaTotalTerrenos(informe.descripcion_referencial, informe.valor_inmueble_terreno, informe.detalle_inmueble[0].area_campo))}</td>
                                    </tr>
                                    : null
                            }
                        </tbody>

                        <tbody>

                            {
                                informe.infraestructura.length > 0 ?
                                    <>
                                        <tr>
                                            <td className="bg-[#BCD4EC] border border-black text-center">Descripción</td>
                                            <td className="bg-[#BCD4EC] border border-black text-center">Área Física en m2</td>
                                            <td className="bg-[#BCD4EC] border border-black text-center">Valor Q. por m2</td>
                                            <td className="bg-[#BCD4EC] border border-black text-center">Valor Q.</td>
                                        </tr>

                                        {
                                            informe.infraestructura.map((item, index) =>
                                                <tr key={index}>
                                                    <td className="bg-[#BCD4EC] border border-black text-center">{item.construccion}</td>
                                                    <td className="border border-black text-center">{convertirTextoANumero(item.area)} m2</td>
                                                    <td className="border border-black text-center">Q. {obtenerValorInfraestructuraPorMetro(item.valor, item.edad_castigo, item.mantenimiento_castigo, item.proceso_constructivo_castigo, item.deseabilidad_castigo)}</td>
                                                    <td className="border border-black text-center">Q. {separator(obtenerValorInfraestructura(item.area, item.valor, item.edad_castigo, item.mantenimiento_castigo, item.proceso_constructivo_castigo, item.deseabilidad_castigo))}</td>
                                                </tr>
                                            )
                                        }

                                        <tr>
                                            <td colSpan="3" className="bg-[#BCD4EC] border border-black text-center">Total:</td>
                                            <td className="font-semibold bg-[#BCD4EC] border border-black text-center">Q. {separator(valorSumatoriaTotalInfraestructura(informe.infraestructura))}</td>
                                        </tr>
                                    </>
                                    : null
                            }

                            <tr>

                                {
                                    informe.infraestructura.length > 0 ?
                                        <td colSpan="3" className="font-semibold bg-[#BCD4EC] border border-black text-center">Total, Terreno(s) y Construcción:</td>
                                        :
                                        <td colSpan="3" className="font-semibold bg-[#BCD4EC] border border-black text-center">Total:</td>
                                }
                                <td className="font-semibold bg-[#BCD4EC] border border-black text-center">Q. {separator(precioTotal(informe.descripcion_referencial, informe.valor_inmueble_terreno, informe.infraestructura, informe.detalle_inmueble[0].area_campo))}</td>
                            </tr>
                        </tbody>

                    </table>
                </div>


                <div className="mt-12 mb-32 mx-12">

                    <p className=" w-1/2">
                        Realizado por:
                    </p>

                    <div className="w-full flex  px-8 mt-40 h-fit">
                        <p className=" w-1/2">
                            <br />
                            {informe.usuario.nombre}<br />
                            Oficial de avalúos<br />
                            Cooperativa Cobán
                        </p>

                        <p className=" w-1/2">
                            Vo.Bo.<br />
                            Hector Augusto García Pop<br />
                            Jefe de Avalúos e Infraestructura<br />
                            Cooperativa Cobán
                        </p >
                    </div >
                </div >

                {
                    informe.anexos.map((item, index) =>
                        <div key={index} className="flex flex-wrap mt-8 mb-12 mx-12">
                            {
                                item.imagenes.map((item2, index) =>
                                    <img key={index} className={`${dimensionesImagen(item)} p-1`} src={item2.url} alt="imagen" />
                                )
                            }
                            <p className="w-full text-center font-semibold">{item.anexo.descripcion}</p>
                        </div>
                    )
                }

            </div >
        </div >
    )
}
