import fileDownload from "js-file-download";
import { apiInforme, apiRest } from "../../../api/apiRest";
import { errorModal, getToken, interceptorResponse, successModal } from "../../../utils";
import { avaluoCredentials, avaluoIndividualClearCredentials, avaluoIndividualCredentials, informeCredentials, isLoading, loaded } from "./avaluoSlice";


export const getAvaluos = (busqueda = '', pagina, estado, filtro_usuario = '', limite = '') => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/avaluo', { headers: { 'token': getToken() }, params: { busqueda, pagina, estado, filtro_usuario, limite } });
            dispatch(avaluoCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}

export const crearAvaluo = (data1, setShow, setValue, funcion, watchItem) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.post('/avaluo/crear', data1, { headers: { 'token': getToken() } });
            dispatch(loaded())
            funcion(watchItem.solicitante, 1);
            setValue('buscador', watchItem.solicitante);
            successModal('Registro creado correctamente', 'center');
            setShow(false);
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error al hacer el guardado');
        }
    }
}

export const obtenerAvaluo = (id_avaluo) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/avaluo/individual', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(avaluoIndividualCredentials(data));
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
        }
    }
}

export const editarAvaluo = (id_avaluo, data1, setShow, setValue, funcion, watchItem) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/avaluo/editar', data1, { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(loaded())
            funcion(watchItem.solicitante, 1);
            setValue('buscador', watchItem.solicitante);
            successModal('Registro editado correctamente', 'center');
            setShow(false);
            dispatch(avaluoIndividualClearCredentials());
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error al hacer la edición');
        }
    }
}

export const informeAvaluo = (id_avaluo, setShow) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            const { data } = await apiRest.get('/avaluo/total', { headers: { 'token': getToken() }, params: { id_avaluo } });
            dispatch(informeCredentials(data));
            setShow(true);
        } catch (error) {
            dispatch(loaded());
            interceptorResponse(error);
            errorModal(error.response.data.msg);
        }
    }
}

export const enviarAprobacionAvaluo = (id_avaluo, funcion) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/avaluo/revision', '', { headers: { 'token': getToken() }, params: { id: id_avaluo } });
            dispatch(loaded())
            funcion('', 1, 0);
            successModal('Avalúo enviado a revisión para su aprobación', 'center');
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error en el envío a revisión');
        }
    }
}

export const enviarAutorizacionAvaluo = (id_avaluo, funcion) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/avaluo/aprobacion', '', { headers: { 'token': getToken() }, params: { id: id_avaluo } });
            dispatch(loaded())
            funcion('', 1, 1);
            successModal('Avalúo aprobado y enviado para su autorización', 'center');
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error en el envío a autorización');
        }
    }
}

export const autorizacionAvaluo = (id_avaluo, funcion) => {
    return async (dispatch) => {
        try {
            dispatch(isLoading());
            await apiRest.put('/avaluo/autorizacion', '', { headers: { 'token': getToken() }, params: { id: id_avaluo } });
            dispatch(loaded())
            funcion('', 1, 2);
            successModal('Avalúo autorizado', 'center');
        } catch (error) {
            dispatch(loaded())
            interceptorResponse(error);
            errorModal('error en la autorización');
        }
    }
}

export const generarInformeAvaluoPDF = (id_avaluo, solicitante) => {
    return async (dispatch) => {
        dispatch(isLoading());
        await apiRest.get('/avaluo/total', { headers: { 'token': getToken() }, params: { id_avaluo } })
            .then((response) => {
                let data1 = { template: { name: "principal" }, data: response.data };

                apiInforme.post('', data1).then((response) => {
                    dispatch(loaded());
                    successModal('Reporte generado correctamente', 'center');
                    const date = `${new Date().getDate()}-${(new Date().getMonth() + 1)}-${new Date().getFullYear()}`;
                    fileDownload(response.data, `Avalúo ${solicitante} ${date}.pdf`);
                }).catch((error) => {
                    dispatch(loaded());
                    errorModal('Ocurrió un error en la generación del documento');
                });

            }).catch((error) => {
                dispatch(loaded());
                errorModal('Ocurrió un error en la carga de información');
            });
    }
}

export const limpiarAvaluoIndividual = () => {
    return async (dispatch) => {
        try {
            dispatch(avaluoIndividualClearCredentials());
        } catch (error) {
            interceptorResponse(error);
            errorModal('error al hacer la limpieza');
        }
    }
}